import { html } from 'lit-element';
import "@material/mwc-icon-button";
import "@material/mwc-icon-button-toggle";
import { DdCard2 } from './dd-card-2.js';
import { EVENTS } from '../../events.js';
import { sharedStyles } from '../../../theme/shared-styles.js';
import style from './dd-recipe-card-2.scss';
import { DISH_TYPE } from '../../../utilities/constants.js';
import { getWebResourceUrl } from '../../../utilities/url-translation.js';

function getFavoriteTitle(isFavorited) {
  return isFavorited? 'remove from Favorites' : 'add to Favorites';
}

function getIngredientTemplate(ingredient) {
  return html`<li class="dd-body1">${ingredient}</li>`;
}

/**
 * menu recipe card
 */
class DdRecipeCard2 extends DdCard2 {
  static get properties() {
    return {
      recipe: { type: Object },
      location: { type: String },
      timeToTable: { type: String },
      _isFavorite: { type: Boolean }
    };
  }

  set recipe(value) {
    this._recipe = value;
    this.requestIsFavorite_();
    this.requestUpdate();
  }

  static get styles() {
    return [
      sharedStyles,
      style
    ];
  }

  render() {
    const baseImageUrl = this._recipe.largeImageUrl;
    const imageUrl = baseImageUrl? getWebResourceUrl(baseImageUrl) : '';

		return html`
      <div class="recipe-container">
        <div class="dd-flex-container">
          <div class="dd-flex-start-container">
            ${this.getLeftControlRowTemplate_()}
          </div>
          <div class="dd-flex-end-container">
            ${baseImageUrl
              ? html`<mwc-icon-button icon="crop_original" @click="${this.onPhotoClick_}"></mwc-icon-button>`
              : ''
            }
            <mwc-icon-button-toggle
              id="favorite-toggle"
              onIcon="favorite"
              offIcon="favorite_border"
              title="${getFavoriteTitle(this._isFavorite)}"
              ?on=${this._isFavorite}
              @click="${this.onFavoriteClicked_}"></mwc-icon-button-toggle>
            <mwc-icon-button icon="cached" @click="${this.onChangeClick_}"></mwc-icon-button>
          </div>
        </div>
        <div id="recipe-content">
          ${this.largeScreen
            ? html`
              <div class="dd-flex-top-container content-container">
                <div class="dd-flex-two-column-child">
                  <div class="dd-overline">ingredients</div>
                  <div class="dd-flex-top-container">
                    ${this.getIngredientTemplates_()}
                  </div>
                </div>
                <div class="dd-flex-two-column-child">
                  <div class="dd-overline">directions</div>
                  <p class="dd-body1 content-container">${this.getInstructions_()}</p>
                </div>
              </div>
            `
            : html`
              <div class="dd-overline">ingredients</div>
              <div class="dd-flex-top-container content-container">
                ${this.getIngredientTemplates_()}
              </div>

              <div class="dd-overline">directions</div>
              <p class="dd-body1 content-container">${this.getInstructions_()}</p>
            `
          }
        </div>


        <div class="dd-flex-end-container">
          <mwc-icon-button icon="delete_outline" title="remove this recipe" @click="${this.onDeleteClick_}"></mwc-icon-button>
        </div>
        <div class="dd-card-bottom-border"></div>
      </div>
      <div id="lightbox" class="modal dd_hide">
        <div class="dd-flex-container dd-expanded-content-block">
          <span class="dd-icon-placeholder"></span>
          <h5>${this._recipe.name}</h5>
          <mwc-icon-button icon="close" @click="${this.onCloseModalClick_}"></mwc-icon-button>
        </div>
        <div class="modal-content">
          <img src="${imageUrl}" alt="${this._recipe.name}">
        </div>
      </div>
    `;
  }

  getLeftControlRowTemplate_() {
    return (this.location === DISH_TYPE.MAIN)
    ? html`
      <span class="material-icons timer">
      schedule
      </span>
      <div>
        <p class="dd-overline">time to table</p>
        <p class="dd-overline">${this.timeToTable}</p>
      </div>
    `
    : html`
      <h6>${this._recipe.name}</h6>
    `;
  }

  getIngredientTemplates_() {
    if (this._recipe && this._recipe.ingredients) {
      const $ingredientCount = this._recipe.ingredients.length;
      const $leftListCount = Math.ceil($ingredientCount / 2);
      const leftList = this._recipe.ingredients.slice(0, $leftListCount);
      const rightList = this._recipe.ingredients.slice($leftListCount);

      return html`
        <ul id="left-ingredients" class="dd-pad-left ingredient-list" slot="content">
          ${leftList.map(getIngredientTemplate)}
        </ul>
        <ul class="dd-pad-left ingredient-list" slot="content">
          ${rightList.map(getIngredientTemplate)}
        </ul>
      `;
    }

    return '';
  }

  getInstructions_() {
    const instructions = this._recipe && this._recipe.instructions ? this._recipe.instructions : '';

    return instructions;
  }

  requestIsFavorite_() {
    const callback = (this.setFavorited_).bind(this);
    const dto = {
      callback,
      recipeId: this._recipe.id
    };

    this.fireGlobalEvent_(EVENTS.REQUEST_FAVORITED, dto);
  }

  setFavorited_(isFavorite) {
    this._isFavorite = isFavorite;
  }

  fireRecipeChangeEvent_(eventName) {
    this.fireLocalEvent_(eventName, this.location);
  }

  onChangeClick_() {
    this.fireRecipeChangeEvent_(EVENTS.REPLACE);
  }

  onDeleteClick_() {
    this.fireRecipeChangeEvent_(EVENTS.REMOVE);
  }

  onFavoriteClicked_(event) {
    const dto = {
      isFavorited: event.target.on,
      recipeId: this._recipe.id,
      dishType: this._recipe.dishType
    }
    this.fireGlobalEvent_(EVENTS.FAVORITE_ACTION, dto);
  }

  onPhotoClick_() {
    const lightbox = this.shadowRoot.querySelector('#lightbox');
    lightbox.classList.replace('dd_hide', 'dd_show');
  }

  onCloseModalClick_() {
    const lightbox = this.shadowRoot.querySelector('#lightbox');
    lightbox.classList.replace('dd_show','dd_hide');
  }
}

customElements.define('dd-recipe-card-2', DdRecipeCard2);