import { html } from 'lit-element';
import { CARD_BODY_ID } from '../../dd-card/dd-card.js';
import { DdActivatableCard2 } from './dd-activatable-card-2.js';
import './dd-card-expander.js';
import { getSmallIconTemplate } from '../../template-helpers.js';
import { SALES_ICON, SALES_FG } from '../../../theme/color-classes.js';
import { sharedStyles } from '../../../theme/shared-styles.js';
import style from './dd-shop-item-card-2.scss';
import '@material/mwc-button';
import '@material/mwc-checkbox';
import '@material/mwc-icon';
import '@material/mwc-icon-button';

import '../../dd-item-editor.js';
import { DISH_TYPE_UNKNOWN } from '../../../utilities/constants.js';
import { toShopItemName } from '../../../utilities/text-format.js';
import { DayMenuColorProvider } from '../../../services/day-menu-color-provider.js';
import { RecipeShopItemMapProvider } from '../../../services/recipe-shop-item-map-provider.js';
import { EVENTS } from '../../events.js';

const MAX_BRAND_LENGTH = 25;
const ELLIPSIS_LENGTH = 3;
const ELLIPSIS = '...';

/**
 * shopping list item card
 */
function getRecipeNameTemplates(recipeNames) {
  return recipeNames.map(recipeName => html`<li class="dd-body1">${recipeName}</li>`);
}
class DdShopItemCard2 extends DdActivatableCard2 {
  static get properties() {
    return {
      shoppingListItem: { type: Object },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      style
    ];
  }

  constructor() {
    super();

    this.shoppingListItem = {
      name: '',
      isChecked: false,
      formattedAmount: '',
      brand: '',
      notes: '',
      dishType: DISH_TYPE_UNKNOWN
    };
  }

  render() {
    const minimized = false;
    const closedIcon = 'more_vert';

    return html`
      <div id="${CARD_BODY_ID}">
        <dd-card-expander
          .closedIcon=${closedIcon}
          ?clickableTitle=${false}
          @pre-open="${this.onExpanderOpen_}"
          @open="${this.layout_}">
          <div class="dd-flex-container" slot="title">
            <div class="dd-body1 dd-row-container">
              ${this.getSelectorTemplate_()}
              <div>
                <div id="button-label" class="dd-flex-start-container">
                  <div class="dd-body1 ${this.getTitleClass_()}">${this.getTitle_()}</div>
                  ${this.getNoteIndicator_()}
                </div>
                ${this.getSaleInformation_(minimized)}
              </div>
            </div>
            <div id="color-key-container">
              ${this.getColorKeyTemplate_()}
            </div>
          </div>
          <div class="" slot="content">
            <div class="dd-item-editor-container">
              <dd-item-editor id="item-editor"
                .name=${this.shoppingListItem.name}
                .amount=${this.shoppingListItem.formattedAmount}
                .brand=${this.shoppingListItem.brand}
                .notes=${this.shoppingListItem.notes}
                .isOnSale=${this.shoppingListItem.isOnSale}
                @item-editor-submit="${this.onItemEditorSubmit_}"></dd-item-editor>
            </div>
            ${this.getRecipeNameSection_()}
            <div class="dd-flex-end-container">
              <mwc-icon-button icon="delete_outline" title="remove this" @click="${this.onDeleteClick_}"></mwc-icon-button>
            </div>
            <div class="dd-card-bottom-border"></div>
          </div>
        </dd-card-expander>
      </div>
		`;
  }

  layout_() {
    const itemEditor = this.shadowRoot.querySelector('#item-editor');
    itemEditor.layout();
  }

  getSelectorTemplate_() {
    const disabled = this.shoppingListItem.isFulfilled;
    const checked = this.shoppingListItem.isChecked || this.shoppingListItem.isFulfilled;

    return html`
      <mwc-checkbox
        .checked="${checked}"
        .disabled="${disabled}"
        @change="${this.onCheckedChange_}"></mwc-checkbox>
    `;
  }

  getColorKeyTemplate_() {
    const recipeIds = RecipeShopItemMapProvider.getRecipeIds(this.shoppingListItem.id);
    const colorClasses = DayMenuColorProvider.getColorClasses(recipeIds);

    return colorClasses.map((colorClass) => html`
      <span class="item-decorator ${colorClass}"></span>
    `);
  }

  getNoteIndicator_() {
    return this.shoppingListItem.notes? html`<mwc-icon class="dd-secondary-dark-fg dd-small-icon dd-margin-left" title="${this.shoppingListItem.notes}">sticky_note_2</mwc-icon>` : '';
  }

  updateActive_() {
    const isUserAdded = this.shoppingListItem.dishType === DISH_TYPE_UNKNOWN;
    this.isActive = RecipeShopItemMapProvider.isItemActive(this.shoppingListItem.id) || isUserAdded;
  }

  getTitle_() {
    const title = this.shoppingListItem && this.shoppingListItem.name ? toShopItemName(this.shoppingListItem) : 'No item name';

    return title;
  }

  getTitleClass_() {
    let titleClass = '';
    if (this.shoppingListItem.isFulfilled) {
      titleClass = 'disabled-text';
    } else if (this.shoppingListItem.isChecked) {
      titleClass = 'strike-through';
    }

    return titleClass;
  }

  getSaleInformation_(isMaximized) {
    const brand = isMaximized || this._largeScreen? this.shoppingListItem.brand : this.getTruncatedBrand_();

    if (this.shoppingListItem.isOnSale) {
      return html`
        <div class="dd-flex-start-container">
          ${getSmallIconTemplate('', SALES_FG, SALES_ICON)}
          <span class="dd-pad-left dd-caption ">${brand}</span>
        </div>
        `;
    }

    return this.shoppingListItem.brand ? html`
      <div class="dd-flex-start-container">
        <span class="dd-pad-left dd-caption ">${brand}</span>
      </div>
      ` : '';
  }

  getTruncatedBrand_() {
    if (this.shoppingListItem.brand && (this.shoppingListItem.brand.length > MAX_BRAND_LENGTH + ELLIPSIS_LENGTH)) {
      return `${this.shoppingListItem.brand.slice(0, MAX_BRAND_LENGTH)}${ELLIPSIS}`;
    }

    return this.shoppingListItem.brand;
  }

  getRecipeNameSection_() {
    const recipeNames = RecipeShopItemMapProvider.getRecipeNames(this.shoppingListItem.id);

    return recipeNames.length > 0 ? html`
      <div class="dd-shop-item-content-container">
        <p class="dd-overline">used in</p>
        <ul class="dd-card-list">
          ${getRecipeNameTemplates(recipeNames)}
        </ul>
      </div>
    `
    : '';
  }

  fireDeleteEvent_() {
    const detail = {
      shopItemId: this.shoppingListItem.id
    };
    this.fireGlobalEvent_(EVENTS.REMOVE_SHOP_ITEM, detail);
  }


	onExpanderOpen_() {
		if (this.shoppingListItem.id) {
			this.isOpen = true;
			this.onMaximize_(this.shoppingListItem.id);
		}
	}

  onCheckedChange_(event) {
    const checkedBox = event.target;
    const detail = {
      isChecked: checkedBox.checked,
      shopItemId: this.shoppingListItem.id
    };
    this.fireGlobalEvent_(EVENTS.CHECKED_SHOP_ITEM, detail);
    this.close();
  }

  onDeleteClick_() {
    this.fireDeleteEvent_();
    this.close();
  }

  onItemEditorSubmit_(event) {
    const detail = {
      ...event.detail,
      shoppingListItemId: this.shoppingListItem.id
    };
    this.fireLocalEvent_(EVENTS.UPDATE, detail);
  }
}

customElements.define('dd-shop-item-card-2', DdShopItemCard2);