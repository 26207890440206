/**
 * API actions
 * ref: https://github.com/redux-utilities/flux-standard-action
 */
import {
	axios,
	getAuthUrl,
	startRequest,
	completeRequest,
	NOK_RESPONSE_HINT
} from './api-base.js';
import { MENU_VIEW, navigate } from '../navigation.js';
import { requestSubscriptionAndPortal } from './subscription-api.js';
import { setToken } from '../token.js';

const TOKEN_REQUEST = 'TOKEN_REQUEST';

// REST resources
const TOKEN_RESOURCE = 'token';

const GRANT_TYPE = 'client_credentials';

/**
 * returns action to monitor with requestMiddleware: { payload: async function }
 */
/* eslint no-undef: "off" */
export function requestToken(email, first, last, name, id, clientId, clientSecret, portalHandler) {
	return {
		type: TOKEN_REQUEST,
		payload (dispatch) {
			dispatch(startRequest());

			const tokenUrl = getAuthUrl(TOKEN_RESOURCE);

			return axios.post(tokenUrl, {
				client_id: clientId,
				client_secret: clientSecret,
				grant_type: GRANT_TYPE,
				email,
				id,
				first,
				last,
				name
			})
				.then(response => {
					dispatch([
						setToken(response.data.token, response.data.refresh_token),
						completeRequest()
					]);

					if (ID_PROVIDER) {
						// subscription thunk
						return dispatch(requestSubscriptionAndPortal(portalHandler));
					}

					dispatch(navigate(MENU_VIEW));
					return false;
				})
				.catch(error => {
					dispatch(completeRequest(NOK_RESPONSE_HINT));
					console.log(error);
				});
		}
	}
}