import { REHYDRATE } from 'redux-persist/es/constants';
import { NAVIGATION_ACTION, NO_VIEW, LOGIN_VIEW } from '../actions/navigation';

const INITIAL_STATE = {
	view: NO_VIEW,
	destination: ''
};

export function navigation(state = INITIAL_STATE, action) {
	const isRehydratingNoState = !action.payload;
	const isRehydratingInitialState = action.payload && action.payload.navigation
		&& ((action.payload.navigation.view === LOGIN_VIEW) || (action.payload.navigation.view === NO_VIEW));

	switch (action.type) {
		case NAVIGATION_ACTION:
			return {
				...state,
				view: action.payload.view,
				destination: action.payload.destination,
				title: action.payload.title
			};
		case REHYDRATE:
				return {
					...state,
					view: isRehydratingNoState || isRehydratingInitialState? state.view : action.payload.navigation.view,
					destination: isRehydratingNoState || isRehydratingInitialState? state.destination : action.payload.navigation.destination,
					title: isRehydratingNoState || isRehydratingInitialState? state.title : action.payload.navigation.title
				};
		default:
			return state;
	}
}
