import { html, css } from 'lit-element';
import "@material/mwc-icon-button";
import { sharedStyles } from '../theme/shared-styles.js';
import style from './dd-print.scss';
import { PRINT_CONTAINER, VIEW_CONTAINER, DdPrint } from './dd-print.js';
import { getCategoryIconTemplate, getSmallIconTemplate } from './template-helpers.js';
import { RecipeShopItemMapProvider } from '../services/recipe-shop-item-map-provider.js';
import { SALES_ICON, SALES_FG } from '../theme/color-classes.js';
import {
	CATEGORY_MAP,
	FAMILY_SIZE,
	SHOPPING_CATEGORY
} from '../utilities/constants.js';
import { toShopItemName } from '../utilities/text-format.js';

/**
 * week menu print view
 */

function getSaleInformation(shoppingItem) {
	if (shoppingItem.isOnSale) {
		return html`
			<div class="dd-flex-start-container">
				${getSmallIconTemplate('', SALES_FG, SALES_ICON)}
				<span class="dd-pad-left print-sale-text">${shoppingItem.brand}</span>
			</div>
			`;
	}

	return shoppingItem.brand ? html`
		<div class="dd-flex-start-container">
			<span class="dd-pad-left">${shoppingItem.brand}</span>
		</div>
		` : '';
}

/**
 * array map callback with passed *this* object
 * @param {*} item ex: state.shoppingList.produce[0]
 * @param array activeDayMenus
 * @returns
 */
function getShoppingItemTemplate(item, activeDayMenus) {
	function getKeyValue(itemId, dayMenus) {
		function dayContainsId(recipeId, dayMenu) {
			return ((recipeId === dayMenu.recipeIds.main) || dayMenu.recipeIds.sides.includes(recipeId));
		}

		const recipeIds = RecipeShopItemMapProvider.getRecipeIds(itemId, false);
		if (recipeIds.length > 1) {
			return '*';
		}

		let index = '';
		for (let i = 0; i < dayMenus.length; i += 1) {
			if (dayContainsId(recipeIds[0], dayMenus[i])) {
				index = i+1;
				break;
			}
		}

		return index;
	}

	const recipeKeyValue = getKeyValue(item.id, activeDayMenus);
	const nameFormatClass = RecipeShopItemMapProvider.isInOnlySides(item.id)? 'italic' : '';

	return html`
		<div class="print-shopping-item-container dd-flex-container">
			<div>
				<span class="${nameFormatClass}">${toShopItemName(item)}</span>
				${getSaleInformation(item)}
			</div>
			<div class="print-recipe-key">${recipeKeyValue}</div>
		</div>
	`;
}

class DdWeekSlPrint extends DdPrint {
	static get properties() {
		return {
			activeDayMenus: { type: Object },	// ref: test/utilities/select-options.test.js
			familySize: { type: Number },
			heading: { type: Object },
			shoppingList: { type: Object }
		};
	}

	static get styles() {
		return [
			sharedStyles,
			style,
			css`
				#print-header {
					padding-bottom: 24px;
				}

				.print-column-container {
					column-count: 3;
					column-gap: 32px;
					page-break-after: auto;
				}

				.print-category-container {
					padding-bottom: 32px;
					break-inside: avoid-column;
				}

				.print-shopping-item-container {
					margin: 8px;
				}

				span.print-view-day-title {
					text-transform: capitalize;
					font-size: 20px;
					font-weight: bold;
				}

				.print-title-connector {
					font-style: italic;
    			text-transform: lowercase;
					margin: 0 0 0 8px;
				}

				span.print-view-subtitle {
					text-transform: capitalize;
					font-size: 18px;
				}

				.print-sale-text {
					color: rgba(0, 0, 0, 0.7);
				}

				.menu-key {
					color: var(--mdc-theme-primary-dark);
					margin: 0 8px 0 0;
				}

				.print-recipe-key {
					color: var(--mdc-theme-primary-dark);
					font-size: 12px;
				}

				.dd-shop-category-header {
					padding: 4px;
					border-top: 2px solid var(--mdc-theme-on-surface);
					border-bottom: 1px solid rgba(0, 0, 0, 0.7);
				}

				.dd-shop-category-title {
					text-transform: capitalize;
  				text-align: center;
					margin-left: 8px;
				}
			`
    ];
  }

	constructor() {
		super();
		this.shoppingList = {
		};
		this.activeDayMenus = [];
	}

  render() {
		this.scrollSpinnerContainer_();
		const planDescription = this.familySize === FAMILY_SIZE.full.value? FAMILY_SIZE.full.description : FAMILY_SIZE.half.description;

    return html`
			<div id="${VIEW_CONTAINER}">
				${this.getScrimTemplate_()}
				<div id="${PRINT_CONTAINER}">
					<div id="print-header" class="dd-flex-container">
						<div>
							<div class="dd-dense-content-item">
								<h4 class="bold_title">${this.heading}</h4>
							</div>
							<div class="dd-dense-content-item">
								<span>Serves ${planDescription}</span>
							</div>
						</div>
						<div>
							${this.getPrintMainNamesTemplate_()}
							<div>
								<span class="menu-key">*</span>
								<span>Item used in multiple recipes</span>
							</div>
						</div>
					</div>
					<div class="print-column-container">
						${this.getCategoryTemplates_()}
					</div>
				</div>
			</div>
    `;
  }

	getCategoryTemplates_() {
		return html`
			${this.getCategoryTemplate_(SHOPPING_CATEGORY.PRODUCE, this.shoppingList.produce)}
			${this.getCategoryTemplate_(SHOPPING_CATEGORY.SEAFOOD, this.shoppingList.seafood)}
			${this.getCategoryTemplate_(SHOPPING_CATEGORY.MEAT_POULTRY, this.shoppingList.meatPoultry)}
			${this.getCategoryTemplate_(SHOPPING_CATEGORY.GROCERY, this.shoppingList.grocery)}
			${this.getCategoryTemplate_(SHOPPING_CATEGORY.DAIRY, this.shoppingList.dairy)}
			${this.getCategoryTemplate_(SHOPPING_CATEGORY.FROZEN_FOODS, this.shoppingList.frozenFoods)}
			${this.getCategoryTemplate_(SHOPPING_CATEGORY.STAPLE, this.shoppingList.staples)}
			${this.getCategoryTemplate_(SHOPPING_CATEGORY.UNKNOWN, this.shoppingList.other)}
		`;
	}

	getCategoryTemplate_(categoryKey, items) {
		return html`
			<div class="print-category-container">
				<div class="dd-shop-category-header dd-flex-start-container">
					${getCategoryIconTemplate(categoryKey)}
					<span class="dd-card-mini-primary-title dd-shop-category-title">${CATEGORY_MAP[categoryKey].name}</span>
				</div>
				<div>
					${this.getShoppingItemTemplates_(items)}
				</div>
			</div>
		`;
	}

	getShoppingItemTemplates_(items) {
		// TODO: pass activeDayMenus
		function callback(item) {
			return getShoppingItemTemplate(item, this.activeDayMenus);
		}

		return items
			? items.map( callback, { activeDayMenus: this.activeDayMenus })
			: '';
	}

	getPrintMainNamesTemplate_() {
		if (this.activeDayMenus) {
			return this.activeDayMenus.map( (activeDayMenu, index) => activeDayMenu.sideNames
				? html`
					<div>
						<span class="menu-key">${index + 1}</span>
						<span class="bold">${activeDayMenu.name}</span>
						<span class="print-title-connector">with </span>
						<span class="italic">${activeDayMenu.sideNames}</span>
					</div>
				`
				: html`
					<div>
						<span class="menu-key">${index + 1}</span>
						<span class="bold">${activeDayMenu.name}</span>
					</div>
				`
			);
		}

		return '';
	}
}

customElements.define('dd-week-sl-print', DdWeekSlPrint);
