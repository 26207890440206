import { html } from 'lit-element';
import "@material/mwc-icon-button";
import "@material/mwc-button";
import "@material/mwc-dialog";
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-select';
import { sharedStyles } from '../../theme/shared-styles.js';
import style from './dd-day-card.scss';
import {
	CARD_BODY_ID,
	CARD_SWIPE_BACKGROUND_ID,
	CONTAINER_ID,
	FULL_WIDTH_CONTENT_ID,
	MAXIMIZED_TOUCH_AREA_CLASS,
	MAX_VIEW_ID,
	MIN_VIEW_ID,
	MINIMIZER_ID,
	BOTTOM_LEFT_CTL_ID,
	BOTTOM_RIGHT_CTL_ID,
	REMOVE_WIDTH,
	REPLACE_WIDTH
} from './dd-card.js';
import { DdActivatableCard } from './dd-activatable-card.js';
import './dd-recipe-card.js';
import { DdCarouselHost } from './dd-carousel-host.js';
import { getImagePlaceholder, getProteinIconTemplate, getProteinNameTemplate } from '../template-helpers.js';
import '../dd-max-height-button.js';
import '../dd-search.js';
import '../dd-dialog.js';
import '../dd-expandable-content.js';
import { EVENTS } from "../events.js";
import { FavoritesProvider, FAVORITES_SET_EVENT } from '../../services/favorites-provider.js';
import { PersonalRecipesProvider, PERSONAL_RECIPES_SET_EVENT } from '../../services/personal-recipes-provider.js';
import { PubSub } from '../../services/pub-sub.js';
import { SideRecipeOptionsProvider } from '../../services/side-recipe-options-provider.js';
import { OPEN_ANIMATION_DELAY } from '../../utilities/constants.js';
import { getDaySidesTitle, toTimeMeasure } from '../../utilities/text-format.js';

export const MAIN_DISH = 'main';
export const FIRST_SIDE = 'first-side';
export const SECOND_SIDE = 'second-side';

const OPTION_SUGGESTIONS = 'option-suggestions';
const OPTION_FAVORITES = 'option-favorites';
const OPTION_SEARCH = 'option-search';
const OPTION_PERSONALS = 'option-personals';
const SUGGESTIONS_TITLE = 'Suggestions';
const FAVORITES_TITLE = 'Favorites';
const SEARCH_TITLE = 'Search';
const PERSONALS_TITLE = 'My Recipes';
const REPLACE_OPTIONS = [
	{
		id: OPTION_SUGGESTIONS,
		name: SUGGESTIONS_TITLE
	},
	{
		id: OPTION_FAVORITES,
		name: FAVORITES_TITLE
	},
	{
		id: OPTION_PERSONALS,
		name: PERSONALS_TITLE
	},
	{
		id: OPTION_SEARCH,
		name: SEARCH_TITLE
	}
];
Object.freeze(REPLACE_OPTIONS);

const FAB_LABEL = 'Add side';
const NUTRITION_DISCLAIMER = 'Nutritional values are a general guideline only. Actual results may vary. Please see our Terms & Conditions for our complete policy.';

function getReplaceOptionsTemplates() {
	return REPLACE_OPTIONS.map((replaceOption) => html`
		<mwc-list-item value="${replaceOption.id}">${replaceOption.name}</mwc-list-item>
		`
	);
}

let cardSwipeSignifierComplete = false;

class DdDayCard extends DdActivatableCard {
	static get properties() {
		return {
			dayMenu: { type: Object },
			searchResults: { type: Object },
		};
	}

	set dayMenu(value) {
    const oldValue = this._dayMenu;
		this._dayMenu = value;
		this._carouselHost = null;

		/*
		const isNewMeal = oldValue && !oldValue.main && value.main;
		if (isNewMeal) {
			const onMaximizeClick = (this.onMaximizeClick_).bind(this);
			setTimeout(() => {
				onMaximizeClick();
			}, ASYNC_PAUSE);
		}
		*/

		this.requestUpdate('dayMenu', oldValue);
	}

	get dayMenu() {
		return this._dayMenu;
	}

	get canAddRecipe() {
		return !(this.hasMain && this.dayMenu.sides && this.dayMenu.sides.length === 2);
	}

	/** pass through to dd-search */
	set searchResults(value) {
		const ddSearch = this.shadowRoot.querySelector('dd-search');
		if (ddSearch) {
			ddSearch.searchResults = value;
		}
	}

	static get styles() {
		return [
			sharedStyles,
			style
		];
	}

	get carouselHost() {
		if (!this._carouselHost) {
			const cards = this.shadowRoot.querySelectorAll('dd-recipe-card');
			const scrollElement = this.largeScreen? null : this._cardBody;
			this._carouselHost = new DdCarouselHost(cards, scrollElement);
		}

		return this._carouselHost;
	}

	set isAddingRecipe(isAddingRecipe) {
		this._isAddingRecipe = isAddingRecipe;
	}

	get isAddingRecipe() {
		return this._isAddingRecipe;
	}

	set dishReplacing(dishReplacing) {
		this._dishReplacing = dishReplacing;
	}

	get dishReplacing() {
		return this._dishReplacing;
	}

	get hasMain() {
		return Boolean(this.dayMenu && this.dayMenu.main);
	}

	constructor() {
		super();
		// overrides
		this._allowPanMinimized = true;
		this._swipeLeftReveal = true;
		this._swipeLeftRevealRange = REPLACE_WIDTH + REMOVE_WIDTH;
		this._containerClass = 'dd-day-card-container';
		this._cardBodyMinClass = 'dd-day-card-minimize';
		this._cardBodySmallScreenMaxClass = 'dd-day-card-body-maximize';
		this._cardBodyLargeScreenMaxClass = 'dd-day-card-lg-screen-maximize';
		this._cardBodyOverlayClass = 'dd-day-card-overlay';
		this._cardBodyOverlayMiddleClass = 'dd-day-card-overlay-middle';

		this._dishReplacing = MAIN_DISH;
		this._isAddingRecipe = false;
		this._carouselHost = null;
		this._hasControlRow = true;
	}

	// BEGIN LitElement lifecycle
	firstUpdated() {
    super.firstUpdated();
		PubSub.subscribe(FAVORITES_SET_EVENT, (this.requestUpdate).bind(this));
		PubSub.subscribe(PERSONAL_RECIPES_SET_EVENT, (this.requestUpdate).bind(this));
	}

	updated() {
		super.updated();

		if (this.dayMenu) {
			this.manageSelectAnimation_(this.dayMenu.id);
		}
	}

	render() {
		const isMain = true;
		const timeToTable = toTimeMeasure(this.dayMenu.timeToTable);
		const minDisplayClass = this.hasMain ? 'dd_show' : 'dd_hide';

		const containerClass = this._isMaxView ? 'dd-day-card-container-max' : `${this._containerClass}`;
		const cardBodyClass = this.hasMain ? this._cardBodyMinClass : 'dd_hide';
		const noteTitleClass = this.largeScreen ? 'bold_title dd-day-note-title-desktop' : 'dd-day-note-title-mobile';

		return html`
		<div id="${CONTAINER_ID}" class="${containerClass}">
			<div id="${CARD_BODY_ID}" class="mdc-card ${cardBodyClass}">
				<div id="${MIN_VIEW_ID}" class="mdc-ripple-surface mdc-ripple-surface--primary dd-content-container dd-body1 ${minDisplayClass}" @click="${this.onMaximizeClick_}">
					<div class="dd-flex-container">
						${this.getProteinIconTemplate_()}
						<div class="dd-left-justify-flex-fill" title="see more">
							<div class="dd-card-mini-primary-title">${this.getTitle_()}</div>
							<div>${this.getSecondTitleTemplate_()}</div>

						</div>
						${this.getDesktopMiniCardDeleteTemplate_(this.onRemoveAndClose_)}
					</div>
				</div>
				<div id="${MAX_VIEW_ID}" class="${this.maxViewClass} ${this.maxViewShowClass}">
					<div>
						<div class="${MAXIMIZED_TOUCH_AREA_CLASS}">
							<div class="dd-lg-screen-adjust dd-flex-container">
								${!this.largeScreen? this.getProteinIconTemplate_(true) : ''}
								${this.getMaxTitleTemplate_()}
								<mwc-icon-button id="${MINIMIZER_ID}" icon="close" @click="${this.onMinimizeClick_}"></mwc-icon-button>
							</div>
							<div class="dd-lg-screen-adjust dd-flex-container dd-content-container dd-day-info">
								${this.largeScreen? this.getProteinIconTemplate_() : ''}
								${this.largeScreen? html`${this.getProteinNameTemplate_()}<span class="dd-bar-spacing">|</span>` : ''}
								<div class="italic">
									${this.largeScreen
										? html`<span id="timer-icon" class="material-icons-small">timer</span>`
										: ''
									}
									<div class="${noteTitleClass}">time to table:</div>
									<span>${timeToTable}</span>
								</div>
								${this.largeScreen && this.dayMenu.cornerNote
									? html`<span class="dd-bar-spacing">|</span>`
									: ''
								}
								<div id="corner-note" class="italic">${this.dayMenu.cornerNote}</div>
							</div>
							${this.largeScreen
								? html`
									<div class="dd-large-screen-control-row-merge-content">
										<dd-expandable-content
												?clickableTitle=${true}
												?isOpen=${false}>
												<span slot="title" class="bold_title">nutrition for meal</span>
												<div slot="content">
													<div>
														<div class="dd-day-nutrition-line">${this.dayMenu.calories} Cals</div>
														<div class="dd-day-nutrition-line">${this.dayMenu.protein}g Protein</div>
														<div class="dd-day-nutrition-line">${this.dayMenu.fat}g Fat</div>
														<div class="dd-day-nutrition-line">${this.dayMenu.fiber}g Fiber</div>
														<div class="dd-day-nutrition-line">${this.dayMenu.carbs}g Carbs</div>
														<div class="dd-day-nutrition-line">${this.dayMenu.saturatedFat}g Saturated Fat</div>
														<div class="dd-day-nutrition-line">${this.dayMenu.sodium}mg Sodium</div>
													</div>
													<div class="dd-view-container">
														<span>${NUTRITION_DISCLAIMER}</span>
													</div>
												</div>
											</dd-expandable-content>
									</div>
								`
								: ''
							}
						</div>
						${this.getDesktopControlRowTemplate_()}
						${this.getRecipeContentTemplate_()}
					</div>
						${this.largeScreen
							? ''
							: html`
							<div class="${MAXIMIZED_TOUCH_AREA_CLASS} dd-flex-container">
								<div id="${BOTTOM_LEFT_CTL_ID}">
									${this.getLeftSwipeAffordance_()}
									<div class="dd-button-placeholder"></div>
								</div>
								<div class="dd-content-container">
									<dd-expandable-content
										?clickableTitle=${true}
										?isOpen=${true}>
										<span slot="title" class="bold_title">nutrition for meal</span>
										<div slot="content">
											<ul class="dd-card-list">
												<li>${this.dayMenu.calories} Cals</li>
												<li>${this.dayMenu.protein}g Protein</li>
												<li>${this.dayMenu.fat}g Fat</li>
												<li>${this.dayMenu.fiber}g Fiber</li>
												<li>${this.dayMenu.carbs}g Carbs</li>
												<li>${this.dayMenu.saturatedFat}g Saturated Fat</li>
												<li>${this.dayMenu.sodium}mg Sodium</li>
											</ul>
											<div class="dd-view-container">
												<span>${NUTRITION_DISCLAIMER}</span>
											</div>
										</div>
									</dd-expandable-content>
								</div>
								${this.getMaxDeleteContainerTemplate_()}
							</div>
							<div class="${MAXIMIZED_TOUCH_AREA_CLASS} dd-max-card-spacer"></div>
						`
					}
				</div>
			</div>
			<div id="${CARD_SWIPE_BACKGROUND_ID}" class="dd-card-swipe-reveal-background dd_hide">
				<div class="dd-card-replace-button ">
          <mwc-icon-button icon="create" @click="${this.onSwipeChangeClick_}"></mwc-icon-button>
        </div>
				<div class="dd-card-remove-button">
          <mwc-icon-button icon="delete_outline" @click="${this.onRemove_}"></mwc-icon-button>
        </div>
			</div>
			<dd-dialog id="replace-dialog" hideActions @closed="${this.onDialogClosed_}">
				<div id="replace-dialog-container">
					<div id="replace-dialog-header" class="dd-flex-top-container">
						<div id="replace-dialog-title" class="dialog-heading">Swap A Recipe</div>
						<mwc-icon-button icon="close" @click="${this.onCloseDialogClick_}"></mwc-icon-button>
					</div>
					<div id="select-box" class="dd-flex-start-container">
						<span class="select-helper-text dd-pad-right">from</span>
						<div class="dd-day-select-container">
							<mwc-select id="replace-option-select"
								outlined
								@action="${this.onReplaceOptionAction_}">
								${getReplaceOptionsTemplates()}
							</mwc-select>
						</div>
					</div>

					<div id="suggested-mains-container" class="dd_hide">
						${this.getSuggestionsTemplate_(isMain)}
					</div>
					<div id="suggested-sides-container" class="dd_hide">
						${this.getSuggestionsTemplate_(!isMain)}
					</div>
					<div id="favorite-mains-container" class="dd_hide">
						${this.getFavoritesTemplate_(isMain)}
					</div>
					<div id="favorite-sides-container" class="dd_hide">
						${this.getFavoritesTemplate_(!isMain)}
					</div>
					<div id="personal-mains-container" class="dd_hide">
						${this.getPersonalsTemplate_(isMain)}
					</div>
					<div id="personal-sides-container" class="dd_hide">
						<span>My Side Recipes will soon be availabe</span>
					</div>
					<div id="search-container" class="dd_hide">
						<dd-search @select-search-option="${this.onSelectSearchOption_}"></dd-search>
					</div>
				</div>
			</dd-dialog>
		</div>
		`;
	}
	// END LitElement lifecycle

	getProteinIconTemplate_(padLeft = false) {
		if (this.dayMenu && this.hasMain) {
			return getProteinIconTemplate(this.dayMenu.main.proteinCategory, this.dayMenu.main.isPersonal, padLeft);
		}

		return getImagePlaceholder();
	}

	getProteinNameTemplate_() {
		if (this.dayMenu && this.hasMain) {
			const proteinCategory = this.dayMenu.main.proteinCategory;
			return getProteinNameTemplate(proteinCategory);
		}

		return '';
	}

	getTitle_() {
		const title = (this.dayMenu && this.dayMenu.mainName) ? this.dayMenu.mainName : 'Add a meal';

		return title;
	}

	getMaxTitleTemplate_() {
		return this.largeScreen
			? html`
			<div class="dd-large-screen-max-title-container">
				<span class="dd-large-screen-max-card-title">${this.getTitle_()}</span>
				${this.getSecondTitleTemplate_()}
			</div>
			`
			: html`<div class="dd-card-title">${this.getTitle_()}</div>`
	}

	getControlRowTemplate_() {
		return html`
			<div class="dd-flex-end-container dd-card-tier1-control-row">
				${this.getDesktopAddRecipeTemplate_()}
				<mwc-icon-button icon="delete_outline" title="remove this meal" @click="${this.onRemoveAndClose_}"></mwc-icon-button>
			</div>
		`;
  }

	getDesktopAddRecipeTemplate_() {
		return this.canAddRecipe? html`
			<div class="dd-card-control-row-raised">
				<mwc-button raised @click="${this.onAddRecipeClick_}">Add Side</mwc-button>
			</div>
		`
		: '';
	}

	getMaxDeleteContainerTemplate_() {
		const hasRecipe = this.dayMenu && (this.hasMain || (Array.isArray(this.dayMenu.sides) && this.dayMenu.sides.length));

		return hasRecipe ? html`
			<div id="${BOTTOM_RIGHT_CTL_ID}">
				${this.getRightSwipeAffordance_()}
				<div class="dd-button-placeholder"></div>
				<mwc-icon-button icon="delete_outline" title="remove this meal" @click="${this.onRemoveAndClose_}"></mwc-icon-button>
			</div>
		` : '';
	}

	getSecondTitleTemplate_() {
		const sidesTitle = getDaySidesTitle(this.dayMenu);

		if (sidesTitle) {
			const textClass = this.largeScreen && this._isMaxView? 'dd-large-screen-max-card-subtitle' : 'dd-card-mini-secondary-title';
			const connector = this.largeScreen && this._isMaxView? html`<span class="dd-large-screen-title-connector">with </span>` : '';

			return html`${connector}<span class="${textClass}">${sidesTitle}</span>`;
		}

		return '';
	}

	getRecipeTemplate_(recipe, location, maximized, allowPanMaximized) {
		if (recipe) {
			return html`
				<dd-recipe-card id="${location}"
					.recipe=${recipe}
					.location=${location}
					?maximized=${maximized}
					?allowMinimize=${!this.largeScreen}
					?allowPanMaximized=${allowPanMaximized}
					@pan-card="${this.onPanCarouselCard_}"
					@reset-pan-card="${this.onResetPanCarouselCard_}"
					@swipe-card="${this.onSwipeCarouselCard_}"
					@maximize-card="${this.onMaximizeCarouselCard_}"
					@minimize-card="${this.onMinimizeCarouselCard_}"
					@replace="${this.onChangeRecipe_}"
					@remove="${this.onDeleteRecipe_}"></dd-recipe-card>
			`;
		}

		return '';
	}

	getDesktopRecipeTemplates_(main, firstSide, secondSide) {
		return html`
			<div id="${FULL_WIDTH_CONTENT_ID}" class="dd-flex-top-container">
				<div class="dd-day-card-main-container">
					${this.getRecipeTemplate_(main, MAIN_DISH, true, false)}
				</div>
				<div class="dd-day-card-sides-container">
					${this.getRecipeTemplate_(firstSide, FIRST_SIDE, true, false)}
					${this.getRecipeTemplate_(secondSide, SECOND_SIDE, true, false)}
				</div>
			</div>
		`;
	}

	getMobileRecipeTemplates_(main, firstSide, secondSide) {
		return html`
			<div id="${FULL_WIDTH_CONTENT_ID}">
				${this.getRecipeTemplate_(main, MAIN_DISH, false, true)}
				${this.getRecipeTemplate_(firstSide, FIRST_SIDE, false, true)}
				${this.getRecipeTemplate_(secondSide, SECOND_SIDE, false, true)}
			</div>
		`;
	}

	getRecipeContentTemplate_() {
		if (this.dayMenu) {
			const main = this.dayMenu.main;
			const firstSide = (this.dayMenu && this.dayMenu.sides) ? this.dayMenu.sides[0] : null;
			const secondSide = this.dayMenu.sides ? this.dayMenu.sides[1] : null;

			if (this.largeScreen) {
				return this.getDesktopRecipeTemplates_(main, firstSide, secondSide);
			}

			return this.getMobileRecipeTemplates_(main, firstSide, secondSide);
		}

		return '';
	}

	/**
	 *
	 * @param {*} dayMenuAlternative ref Symfony \AppBundle\Controller\Rest\DTO\DayMenuAlternative
	 */
	getOptionTemplate_(dayMenuAlternative) {
		return html`
			<div class="option-container">
				<div class="dd-icon-placeholder">
					${dayMenuAlternative.isOnSale
						? html`<img src="images/sale.png" title="On sale!">`
						: ''
					}
				</div>
				<div class="option-button-container">
					<dd-max-height-button raised dense label="${dayMenuAlternative.name}"
						.idValue=${dayMenuAlternative.id}
						@click="${this.onRecipeOptionClick_}"></dd-max-height-button>
				</div>
			</div>
		`;
	}

	getSuggestionsTemplate_(isMain) {
		const suggestions = this.getSuggestions_(isMain);
		return suggestions.map(suggestion => this.getOptionTemplate_(suggestion), this);
	}

	getFavoritesTemplate_(isMain) {
		const favorites = isMain ? FavoritesProvider.getMains() : FavoritesProvider.getSides();

		if (favorites && favorites.length) {
			return favorites.map(favorite => this.getOptionTemplate_(favorite), this);
		}

		return html`<span>You don't have any Favorites yet. Just tap the heart on an opened recipe card to save it as a Favorite.</span>`;
	}

	getPersonalsTemplate_(isMain) {
		const personals = isMain ? PersonalRecipesProvider.getMains() : PersonalRecipesProvider.getSides();

		if (personals && personals.length) {
			return personals.map(personal => this.getOptionTemplate_(personal), this);
		}

		return html`<span>Nothing here yet. Just tap My Recipes to create one.</span>`;
	}

	showFab_() {
		const recipeCardMaximized = this._carouselHost && this._carouselHost.maximizedCard;
		if (this._dayMenu.main && this.canAddRecipe && !recipeCardMaximized) {
			super.showFab_(FAB_LABEL, this.onAddRecipeClick_);
		}
	}

	hideFab_() {
		super.hideFab_(FAB_LABEL);
	}

	updateActive_() {
		this.isActive = this.hasMain || this._isMaxView;
  }

	onAddRecipeClick_() {
		// start with main dish if null, then first side, then second side
		this.isAddingRecipe = true;
		let location = MAIN_DISH;
		if (this.hasMain) {
			location = this.dayMenu.sides[0] ? SECOND_SIDE : FIRST_SIDE;
		}
		this.openReplaceDialog_(location, true);
	}

	getSuggestions_(isMain) {
		if (isMain) {
			return this.dayMenu.mainRecipeOptions;
		}

		return SideRecipeOptionsProvider.getOptions();
	}

	// BEGIN day card carousel methods
	onPan(event) {
		super.onPan(event);

		if (this.dayMenu.mainName) {
			this.handlePan(event);

			if (this.hasFocus) {
				this.hideFab_();
				this.firePanEvent_();
			}
		}
	}

	setOffSetPosition(offsetNumber) {
		super.setOffSetPosition(offsetNumber);
		this.showFab_();
	}

	rewindPan() {
		super.rewindPan();
		this.showFab_();
	}
	// END day card carousel methods

	// BEGIN recipe card carousel methods
	onMaximizeCarouselCard_(event) {
		this.hideFab_();

		this.carouselHost.handleCardMaximized(event.target);
	}

	onMinimizeCarouselCard_(event) {
		this.carouselHost.handleCardMinimized(event.detail.number);
		this.showFab_();
	}

	onPanCarouselCard_(event) {
		this.carouselHost.handlePan(event.detail.number, event.detail.deltaX);
	}

	onResetPanCarouselCard_() {
		this.carouselHost.handleResetPan();
	}

	onSwipeCarouselCard_(event) {
		this.carouselHost.handleSwipe(event.detail.number, event.detail.direction);
	}
	// END recipe card carousel methods

	setInitialReplaceOption_() {
		const replaceOptionSelect = this.shadowRoot.querySelector('#replace-option-select');

		if (replaceOptionSelect) {
			replaceOptionSelect.value = replaceOptionSelect.value || OPTION_SUGGESTIONS;

			this.showReplaceOption_(replaceOptionSelect.value);
		}
	}

	showReplaceOption_(selectValue) {
		switch (selectValue) {
			case OPTION_SUGGESTIONS:
				this.showSuggestionList_();
				break;
			case OPTION_FAVORITES:
				this.showFavoriteList_();
				break;
			case OPTION_PERSONALS:
				this.showPersonalList_();
				break;
			case OPTION_SEARCH:
				this.showSearchList_();
				break;
			case '':
			default:
				break;
		}
	}

	openReplaceDialog_(location, isAdd = false) {
		this.dishReplacing = location;

		const titleVerb = isAdd? 'Add' : 'Swap';
		const titleNoun = (location === MAIN_DISH)? 'Main' : 'Side';
		const dialogTitle = this.shadowRoot.querySelector('#replace-dialog-title');
		dialogTitle.innerHTML = `${titleVerb} A ${titleNoun} Recipe`;

		this.setInitialReplaceOption_();
		const dialog = this.shadowRoot.querySelector('#replace-dialog');
		dialog.show();
	}

	closeReplaceDialog_() {
		const radioSelector = this.shadowRoot.querySelector('#replace-option-select');
		if (radioSelector.value === OPTION_SEARCH) {
			this.fireClearSearchEvent_();
		}

		this.hideAllReplacementChoices_();
		const replaceDialog = this.shadowRoot.querySelector('#replace-dialog');
		replaceDialog.close();

		const recipeCards = this.shadowRoot.querySelectorAll('dd-recipe-card');
		recipeCards.forEach(recipeCard => recipeCard.rewindPan());
	}

	showSuggestedMains_() {
		const containerClasses = this.shadowRoot.querySelector('#suggested-mains-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
	}

	hideSuggestedMains_() {
		const containerClasses = this.shadowRoot.querySelector('#suggested-mains-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	showSuggestedSides_() {
		const containerClasses = this.shadowRoot.querySelector('#suggested-sides-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
	}

	hideSuggestedSides_() {
		const containerClasses = this.shadowRoot.querySelector('#suggested-sides-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	showFavoriteMains_() {
		const containerClasses = this.shadowRoot.querySelector('#favorite-mains-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
	}

	hideFavoriteMains_() {
		const containerClasses = this.shadowRoot.querySelector('#favorite-mains-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	showFavoriteSides_() {
		const containerClasses = this.shadowRoot.querySelector('#favorite-sides-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
	}

	hideFavoriteSides_() {
		const containerClasses = this.shadowRoot.querySelector('#favorite-sides-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	showPersonalMains_() {
		const containerClasses = this.shadowRoot.querySelector('#personal-mains-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
	}

	hidePersonalMains_() {
		const containerClasses = this.shadowRoot.querySelector('#personal-mains-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	showPersonalSides_() {
		const containerClasses = this.shadowRoot.querySelector('#personal-sides-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
	}

	hidePersonalSides_() {
		const containerClasses = this.shadowRoot.querySelector('#personal-sides-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	showSearch_(isMain) {
		const containerClasses = this.shadowRoot.querySelector('#search-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
		const ddSearch = this.shadowRoot.querySelector('dd-search');
		ddSearch.isMain = isMain;
		ddSearch.focus();
	}

	hideSearch_() {
		const containerClasses = this.shadowRoot.querySelector('#search-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	hideAllReplacementChoices_() {
		this.hideSuggestedMains_();
		this.hideSuggestedSides_();
		this.hideFavoriteMains_();
		this.hideFavoriteSides_();
		this.hideSearch_();
	}

	onChangeRecipe_(event) {
		this.isAddingRecipe = false;
		this.openReplaceDialog_(event.detail);
	}

	minimize_() {
		super.minimize_();

		if (!this.hasMain) {
			const containerClasses = this.shadowRoot.querySelector(`#${CONTAINER_ID}`).classList;
			containerClasses.replace('dd_show', 'dd_hide');
			const minViewClasses = this.shadowRoot.querySelector(`#${MIN_VIEW_ID}`).classList;
			minViewClasses.replace('dd_show', 'dd_hide');
		}
	}

	maximize_(offsetNumber) {
		super.maximize_(offsetNumber);

		const container = this.shadowRoot.querySelector(`#${CONTAINER_ID}`);
		if (container && this.hasMain) {
			container.classList.replace('dd_hide', 'dd_show');

			if (this.largeScreen) {
				const recipeCards = this.shadowRoot.querySelectorAll('dd-recipe-card');
				recipeCards.forEach((recipeCard) => {
					recipeCard.maximize();
				});
			}
		}
	}

	onMinimizeClick_() {
		this.hideFab_();
		super.onMinimizeClick_();
		this.updateActive_();
	}

	onMaximizeClick_() {
		super.onMaximizeClick_();
		this.showFab_();

		if (!this.largeScreen && this.dayMenu && !cardSwipeSignifierComplete) {
			const mainCard = this.shadowRoot.querySelector(`#${MAIN_DISH}`);
			cardSwipeSignifierComplete = true;

			setTimeout(() => {
				mainCard.showSwipeSignifierAnimation();
			}, OPEN_ANIMATION_DELAY);
		}
	}

	onDialogClosed_(event) {
		const tappedOutsideDialog = (event.detail && (event.detail.action === "close"));
		if (tappedOutsideDialog) {
			this.onCloseDialogClick_();
			this.fireLocalEvent_(EVENTS.CLOSE_DIALOG, {});
		}
	}

	onCloseDialogClick_() {
		this.closeReplaceDialog_();
		this.rewindPan();
		if (!this.hasMain) {
			this.onMinimizeClick_();
		}
	}

	onReplaceOptionAction_(event) {
		this.showReplaceOption_(event.target.value);
	}

	showSuggestionList_() {
		if (this.dishReplacing === MAIN_DISH) {
			this.showSuggestedMains_();
			this.hideSuggestedSides_();
		} else {
			this.hideSuggestedMains_();
			this.showSuggestedSides_();
		}

		this.hideFavoriteMains_();
		this.hideFavoriteSides_();
		this.hidePersonalMains_();
		this.hidePersonalSides_();
		this.hideSearch_();
	}

	showFavoriteList_() {
		if (this.dishReplacing === MAIN_DISH) {
			this.showFavoriteMains_();
			this.hideFavoriteSides_();
		} else {
			this.hideFavoriteMains_();
			this.showFavoriteSides_();
		}

		this.hidePersonalMains_();
		this.hidePersonalSides_();
		this.hideSuggestedMains_();
		this.hideSuggestedSides_();
		this.hideSearch_();
	}

	showSearchList_() {
		this.showSearch_(this.dishReplacing === MAIN_DISH);

		this.hideSuggestedMains_();
		this.hideSuggestedSides_();
		this.hideFavoriteMains_();
		this.hideFavoriteSides_();
		this.hidePersonalMains_();
		this.hidePersonalSides_();
	}

	showPersonalList_() {
		if (this.dishReplacing === MAIN_DISH) {
			this.showPersonalMains_();
			this.hidePersonalSides_();
		} else {
			this.hidePersonalMains_();
			this.showPersonalSides_();
		}

		this.hideFavoriteMains_();
		this.hideFavoriteSides_();
		this.hideSuggestedMains_();
		this.hideSuggestedSides_();
		this.hideSearch_();
	}

	// BEGIN event generators
	onDeleteRecipe_(event) {
		const location = event.detail;
		if (location === MAIN_DISH) {
			this.onRemoveAndClose_();
		} else {
			const detail = {
				location,
				dayMenuId: this.dayMenu.id
			};
			this.fireGlobalEvent_(EVENTS.DELETE_RECIPE, detail);
		}
	}

	onSwipeChangeClick_() {
		const event = {
			detail: MAIN_DISH
		};
		this.onChangeRecipe_(event);
	}

	onRemoveAndClose_() {
		this.onMinimizeClick_();
		this.onRemove_();
	}

	onRemove_() {
		this.fireGlobalEvent_(EVENTS.DELETE_RECIPES, this.dayMenu.id);
		this.rewindPan();
	}

	onRecipeOptionClick_(event) {
		this.fireRecipeChangeEvent_(event.target.idValue);
	}

	onSelectSearchOption_(event) {
		this.fireRecipeChangeEvent_(event.detail.recipeId);
	}

	fireRecipeChangeEvent_(recipeId) {
		// event to bubble out of parent's shadow DOM
		const eventName = this.isAddingRecipe ? EVENTS.ADD_RECIPE : EVENTS.REPLACE_RECIPE;
		const detail = {
			location: this.dishReplacing,
			dayMenuId: this.dayMenu.id,
			recipeId
		};
		this.fireGlobalEvent_(eventName, detail);
		this.closeReplaceDialog_();
	}

	fireClearSearchEvent_() {
		this.fireGlobalEvent_(EVENTS.CLEAR_SEARCH, {});
	}
	// END event generators
}

customElements.define('dd-day-card', DdDayCard);