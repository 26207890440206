/**
 * card carousel host behavior
 */
import {
  CAROUSEL_ORDER,
  SWIPE_DIRECTION,
  SNAP_DURATION
} from './dd-card.js';
import { SCROLL_BEHAVIOR_START } from '../../utilities/constants.js';
import { isMobileTouch } from '../../utilities/touch-status.js';

function initializeCarouselCards(cards) {
  const maxIndex = cards.length - 1;
  cards.forEach((card, index) => {
    let orderCategory = CAROUSEL_ORDER.middle;
    if (index === 0) {
      orderCategory = maxIndex? CAROUSEL_ORDER.first : CAROUSEL_ORDER.only;
    } else if (index === maxIndex) {
      orderCategory = CAROUSEL_ORDER.last;
    }

    card.carouselNumber = index;
    card.carouselOrder = orderCategory;
  });
}

export class DdCarouselHost {
  get maximizedCard() {
    return this._maximizedCard;
  }

  set maximizedCard(maximizedCard) {
    this._maximizedCard = maximizedCard;
  }

  /**
   * instance this on maximizing a card
   * @param DdCard[] cards
   */
  constructor(cards, parentElement = null) {
    initializeCarouselCards(cards);
    this._cards = cards;
    this._parentElement = parentElement;
    this._maximizedCard = null;
  }

  handleCardMaximized(maximizedCard) {
      this.maximizedCard = maximizedCard;
      this._cards.forEach(card => {
        const offSet = card.carouselNumber - maximizedCard.carouselNumber;
        if (offSet !== 0) {
          if (isMobileTouch()) {
            card.maximize(offSet);
          } else {
            card.minimize();
          }
        }
      });

      if (this._parentElement && isMobileTouch()) {
        this._parentElement.scrollIntoView(SCROLL_BEHAVIOR_START);
      }
  }

  handleCardMinimized(carouselNumber) {
    this.maximizedCard = null;

    if (isMobileTouch()) {
      this._cards.forEach(card => {
        const isNotfocused = card.carouselNumber !== carouselNumber;
        if (isNotfocused) {
          card.minimize();
        }
      });
    }
  }

  handlePan(carouselNumber, deltaX) {
    if (isMobileTouch()) {
      // console.log(`panning: deltaX=${deltaX}, number=${carouselNumber}`);
      const isNoticeablePanEvent = (deltaX < -1) || (deltaX > 1);

      if (isNoticeablePanEvent) {
        this._cards.forEach(card => {
          const isNotfocused = card.carouselNumber !== carouselNumber;
          if (isNotfocused) {
            card.panX(deltaX);
          }
        });
      }
    }
  }

  handleResetPan() {
    if (isMobileTouch()) {
      this._cards.forEach(card => {
        card.rewindPan();
      });
    }
  }

  handleSwipe(carouselNumber, direction) {
    if (isMobileTouch()) {
      //  console.log(`swiped: number=${carouselNumber}`);
      const isSwipeLeft = (direction === SWIPE_DIRECTION.left);
      const nextFocusCardNumber = isSwipeLeft ? carouselNumber + 1 : carouselNumber - 1;

      this._cards.forEach(card => {
        if (card._isMaxView) {
          card.stopTouchManagers();
          const swipeRange = isSwipeLeft ? -card.swipeRange : card.swipeRange;

          const isSwipeFrom = card.carouselNumber === carouselNumber;
          const isSwipeTo = card.carouselNumber === nextFocusCardNumber;
          if (isSwipeFrom || isSwipeTo) {
            card.snapToPosition_(swipeRange);
          } else {
            card.panToPosition_(swipeRange);
          }

          // delay stop until after snap animation (hack to avoid sequencing)
          const offSetNumber = card.carouselNumber - nextFocusCardNumber;
          if (offSetNumber === 0) {
            // new focused card
            this.maximizedCard = card;
            card.layout();
          }
          setTimeout(() => {
            card.setOffSetPosition(offSetNumber);
            card.stopPan();
          }, SNAP_DURATION);
        }
      });
    }
  }
}
