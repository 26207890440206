import { LitElement, html, css } from "lit-element";
import cloneDeep from 'lodash-es/cloneDeep.js';
import { sharedStyles } from '../../theme/shared-styles.js';
import '../dd-expandable-content.js';
import { DECORATOR_SEPARATOR } from '../dd-select-option.js';
import { DIETARY_RESTRICTION_CLASS } from '../../theme/color-classes.js';
import {
  BASICS_VEGETARIAN_LIMIT,
  DIETARY_RESTRICTION,
  DIETARY_RESTRICTION_MAP
} from '../../utilities/constants.js';

const DIETARY_NEEDS_CHANGED_EVENT = 'dietary-needs-changed';

export class DdDietaryNeeds extends LitElement {
  static properties = {
    dietPlanOptions: { type: Object },
    includeHeartHealthy: { type: Boolean, attribute: 'include-hh' }
  };

  set dietPlanOptions(value) {
    const oldValue = this._dietPlanOptions;
    this._dietPlanOptions = value;
    this.requestUpdate('dietPlanOptions', oldValue);
  }

  get dietPlanOptions() {
    // LitElement rendering property requires new object to be returned

    const dto = this.dietPlanOptionsDto;
    const dietPlanOptions = { ...cloneDeep(this._dietPlanOptions), ...dto };
    this.restrictFoodPreferences_(dietPlanOptions);

    return dietPlanOptions;
  }

  get dietPlanOptionsDto() {
    const dto = {};

    const selectOptions = this.shadowRoot.querySelectorAll('dd-select-option');
    selectOptions.forEach(selectOption => {
      const option = selectOption.option;
      dto[option.id] = selectOption.value;
    });

    return dto;
  }

  constructor() {
    super();
    this._dietPlanOptions = {};
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        .select-container {
          padding-left: 16px;
        }

         .expander-content {
          padding-bottom: 16px;
        }

        .dd-line-divider {
          margin-top: 16px;
        }

        @media only screen and (min-width: 1248px) {
          padding.hint {
            margin-top: 0;
          }
        }
      `
    ];
  }

  render() {
    return html`
      <div class="select-container split-section">
        <div class="split-section-child">
          ${this.getSelectOptionTemplate_(DIETARY_RESTRICTION.DAIRY_FREE, this._dietPlanOptions.dairyFree, true)}
          ${this.getSelectOptionTemplate_(DIETARY_RESTRICTION.GLUTEN_FREE, this._dietPlanOptions.glutenFree, false)}
          ${this.getSelectOptionTemplate_(DIETARY_RESTRICTION.REDUCED_CARB, this._dietPlanOptions.reducedCarb, true)}
          ${this.getSelectOptionTemplate_(DIETARY_RESTRICTION.TREE_NUT_FREE,  this._dietPlanOptions.treeNutFree, false)}
          ${this.getSelectOptionTemplate_(DIETARY_RESTRICTION.PEANUT_FREE, this._dietPlanOptions.peanutFree, true)}
        </div>
        <div class="split-section-child">
          ${this.getSelectOptionTemplate_(DIETARY_RESTRICTION.SOY_FREE, this._dietPlanOptions.soyFree, false)}
          ${this.getSelectOptionTemplate_(DIETARY_RESTRICTION.EGG_FREE, this._dietPlanOptions.eggFree, true)}
          <div class="dd-line-divider"></div>
          ${ this.includeHeartHealthy
            ? html`
              ${this.getSelectOptionTemplate_(DIETARY_RESTRICTION.HEART_HEALTHY, this._dietPlanOptions.heartHealthy, false)}
              <div class="detail-expander-container">
                <dd-expandable-content id="heart-healthy-expandable"
                  ?clickableTitle=${true}>
                  <span class="dd-overline" slot="title">details</span>
                  <div class="expander-content" slot="content">
                    <span class="dd-body1">Our Heart Healthy menus are approved by registered dietitians and follow American Heart Association guidelines for daily intake of sodium and saturated fats. They do not contain beef or pork and each meal contains less than 800 mg sodium.</span>
                    <br><br>
                    <span class="dd-body1 italic">Note: These menus do not support all dietary needs, such as gluten-free or reduced carb.</span>
                  </div>
                </dd-expandable-content>
              </div>
            `
            : ''
          }
          ${this.getSelectOptionTemplate_(DIETARY_RESTRICTION.STICK_TO_BASICS, this._dietPlanOptions.stickToBasics, true)}
          <div class="detail-expander-container">
            <dd-expandable-content  id="stick-to-basics-expandable"
              ?clickableTitle=${true}>
              <span class="dd-overline" slot="title">details</span>
              <div class="expander-content"  slot="content">
                <span class="dd-body1">This option uses more traditional ingredients and fewer spicy or international flavors</span>
              </div>
            </dd-expandable-content>
          </div>
        </div>
      </div>
    `;
  }

  reset() {
    const selectOptions = this.shadowRoot.querySelectorAll('dd-select-option');
		selectOptions.forEach(selectOption => {
			selectOption.reset();
		});
  }

  getSelectOptionTemplate_(key, isChecked, isGray) {
    const option = DIETARY_RESTRICTION_MAP[key];
    option.decorator = `${DIETARY_RESTRICTION_CLASS[key].icon}${DECORATOR_SEPARATOR}${DIETARY_RESTRICTION_CLASS[key].fg}`;
    const hhCompatible = (key === DIETARY_RESTRICTION.HEART_HEALTHY)
      || !this.dietPlanOptions.heartHealthy
      || (this.dietPlanOptions.heartHealthy && option.hhCompatible);

    return html`
      <dd-select-option
        .enabled=${hhCompatible}
        .isGray=${isGray}
        .rtl=${true}
        .option=${option}
        .checked=${isChecked}
        @disabled-click="${this.onDisabledClick_}"
        @check-select-option="${this.onDietaryRestrictionChecked_}"
        @uncheck-select-option="${this.onDietaryRestrictionUnchecked_}"></dd-select-option>
    `;
  }

  getExpander_(id) {
    let expander = null;

    switch (id) {
      case (DIETARY_RESTRICTION.HEART_HEALTHY):
        expander = this.shadowRoot.getElementById('heart-healthy-expandable');
        break;
      case (DIETARY_RESTRICTION.STICK_TO_BASICS):
        expander = this.shadowRoot.getElementById('stick-to-basics-expandable');
        break;
      default:
        break;
    }

    return expander;
  }

  restrictFoodPreferences_(dietPlanOptions) {
    if (dietPlanOptions.stickToBasics && dietPlanOptions.vegetarian.value > BASICS_VEGETARIAN_LIMIT) {
      dietPlanOptions.vegetarian.value = BASICS_VEGETARIAN_LIMIT;
    }

    if (this.includeHeartHealthy && dietPlanOptions.heartHealthy) {
      dietPlanOptions.redMeat.value = 0;
      dietPlanOptions.pork.value = 0;
    }
  }

  /* eslint class-methods-use-this: "off" */
  onDisabledClick_() {
    /* stub

		if (this.dietPlanOptions.heartHealthy) {
			this.showSnackBarHint_(HH_INCOMPATIBLE_HINT);
		} else if (event.detail.id === 'stickToBasics') {
			this.showSnackBarHint_(BASICS_UNAVAILABLE_HINT);
		}
    */
	}

  updateHhCompatibility_(eventTarget) {
    if (eventTarget.option.id === DIETARY_RESTRICTION.HEART_HEALTHY) {
      const newValue = eventTarget.getCurrentValue();
      const selectOptions = this.shadowRoot.querySelectorAll('dd-select-option');
      selectOptions.forEach(selectOption => {
          const hhCompatible = !newValue || (newValue && selectOption.option.hhCompatible);
          selectOption.enabled = hhCompatible;

          if (!hhCompatible) {
            selectOption.checked = false;
            selectOption.reset();
          }
      });
    }
  }

  onDietaryRestrictionChecked_(event) {
    const expander = this.getExpander_(event.target.option.id);
    if (expander) {
      expander.open();
    }

    this.updateHhCompatibility_(event.target);
    this.fireDietaryNeedsChangedEvent_();
  }

  onDietaryRestrictionUnchecked_(event) {
    const expander = this.getExpander_(event.target.option.id);
    if (expander) {
      expander.close();
    }

    this.updateHhCompatibility_(event.target);
    this.fireDietaryNeedsChangedEvent_();
  }

  fireDietaryNeedsChangedEvent_() {
    this.dispatchEvent(new CustomEvent(DIETARY_NEEDS_CHANGED_EVENT, {
      detail: {
        dietaryNeeds: this.dietPlanOptions
      },
    }));
  }
}

customElements.define("dd-dietary-needs", DdDietaryNeeds);