
/**
 * feature level provider service
 */
import { PubSub } from './pub-sub.js';
import { SUBSCRIPTION_STATUS ,ASYNC_PAUSE } from '../utilities/constants.js';


export const FEATURE_LEVEL = {
  ZERO: 'feature-level-zero',
  ONE: 'feature-level-one'
};
Object.freeze(FEATURE_LEVEL);

export const LEVEL_CHANGE_EVENT = 'level-change';

export const AppFeatureLevelProvider = {
  _subscription: {},

  setSubscription (subscription) {
    const isInitializing = !('status' in this._subscription) && subscription.status;
    const statusChanged = (isInitializing  && (subscription.status !== SUBSCRIPTION_STATUS.NONE))
      || (!isInitializing && (this._subscription.status !== subscription.status));
    this._subscription = subscription;

    if (statusChanged) {
      // async to allow triggering actions from stateChanged handler
      const newLevel = this.getLevel();
      setTimeout(() => {
        PubSub.publish(LEVEL_CHANGE_EVENT, newLevel);
      }, ASYNC_PAUSE);
    }
  },

  getLevel() {
    if (typeof ID_PROVIDER === 'undefined') {
      return FEATURE_LEVEL.ONE;
    }

    switch (this._subscription.status) {
      case SUBSCRIPTION_STATUS.FREE:
        return FEATURE_LEVEL.ONE;
      case SUBSCRIPTION_STATUS.ACTIVE:
        return FEATURE_LEVEL.ONE;

      case SUBSCRIPTION_STATUS.NONE:
        return FEATURE_LEVEL.ZERO;
      case SUBSCRIPTION_STATUS.CANCELLED:
        return FEATURE_LEVEL.ZERO;
      default:
        return FEATURE_LEVEL.ZERO;
    }
  }
};