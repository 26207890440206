import { html, css } from 'lit-element';
import cloneDeep from 'lodash-es/cloneDeep.js';
import { PageViewElement } from '../page-view-element.js';
import { getSaleTitleTemplate } from '../template-helpers.js';
import { sharedStyles } from '../../theme/shared-styles.js';

// TDD components
import '../dd-budget-range-chart.js';
import './dd-card/dd-shop-category-card-2.js';
import { EVENTS } from '../events.js';
import { AppFlagProvider } from '../../services/app-flag-provider.js';
import { FeatureProvider } from '../../services/feature-provider.js';
import { FulfillmentAccountProvider, OAUTH_CONNECTED_EVENT } from '../../services/fulfillment-account-provider.js';
import { KrogerProductsProvider } from '../../services/kroger-products-provider.js';
import { PubSub } from '../../services/pub-sub.js';
import { RecipeShopItemMapProvider } from '../../services/recipe-shop-item-map-provider.js';
import { sortByName } from '../../utilities/array-helpers.js';
import {
	ASYNC_PAUSE,
	FEATURES,
	OAUTH_SERVICE,
	PRODUCTS_SET_EVENT,
	SHOPPING_CATEGORY
} from '../../utilities/constants.js';
import { getCostMetrics } from '../../utilities/shopping-list.js';

const ADD_SHOP_ITEM_EVENT = 'add-shop-item';
const OAUTH_CONNECT_EVENT = 'oauth-connect';
const ELECT_FULFILLMENT_EVENT = 'elect-fulfillment';

const isChecked = (sli) => sli.isChecked && !sli.isFulfilled;

/**
 * shopping list screen
 */
class DdShoppingList2 extends PageViewElement {
	static get properties() {
		return {
			shoppingList: { type: Object },
			activeDayMenus: { type: Object },
			sortSimilar: { type: Boolean },
			title: { type: String }
		};
	}

	set shoppingList(value) {
		const oldValue = this._shoppingListOriginal;
		this._shoppingListOriginal = value;
		this.updateShoppingListPresentation_();

		this.requestUpdate('shoppingList', oldValue);
	}

	set sortSimilar(value) {
		const oldValue = this._sortSimilar;
		const isChanged = value !== oldValue;
		this._sortSimilar = value;

		if (isChanged) {
			this.updateShoppingListPresentation_();
		}

		this.requestUpdate('sortSimilar', oldValue);
	}

	static get styles() {
		return [
			sharedStyles,
			css`
				.grid-outer {
					padding: 24px 24px 8px 24px;
				}

				#categories-title {
					margin-top: 16px;
					text-transform: capitalize;
				}

				.dd-expanded-content-block {
						padding: 24px 0 16px 0;
				}

				@media only screen and (min-width: 1248px) {
					.grid-outer {
						padding: 24px 24px 8px 32px;
					}

					.section-group {
						align-items: start;
					}

					.section-group > .dd-flex-two-column-child:first-child {
						margin-right: 32px;
					}

					.dd-expanded-content-block {
						padding: 0 0 8px 0;
					}

					#categories-title {
						margin-bottom: 12px;
					}

					dd-shop-category-card-2 {
						width: 100%;
						margin: 12px 0;
					}
				}
			`
		];
	}

	constructor() {
		super();

		this._sortSimilar = true;	// default on
		this._shoppingListOriginal = {};
		this._shoppingListPresentation = {
			name: '',
			dairy: [],
			frozenFoods: [],
			grocery: [],
			meatPoultry: [],
			produce: [],
			seafood: [],
			other: [],
			staples: []
		};
		this.activeDayMenus = [];
	}

	render() {
		const stateInitialized = (this._shoppingListPresentation.dairy.length ||
			this._shoppingListPresentation.frozenFoods.length ||
			this._shoppingListPresentation.grocery.length ||
			this._shoppingListPresentation.meatPoultry.length ||
			this._shoppingListPresentation.produce.length ||
			this._shoppingListPresentation.seafood.length);

		if (!stateInitialized) {
			return '';
		}
		return html`
			<div class="grid-outer">
				<div id="grid-inner">

					${this.largeScreen
						? html`
							<div class="dd-flex-container">
								${getSaleTitleTemplate(this.title)}
								<div class="dd-flex-end-container dd-expanded-content-block">
									${this.getOrderTemplate_()}
									<mwc-button
										outlined
										class="dark-filled"
										icon="print"
										label="print"
										title="print this"
										@click="${this.onPrintClick_}"></mwc-button>
								</div>
							</div>
							<div class="dd-flex-container section-group">
								<div class="dd-flex-two-column-child">
									<div id="mobile-selector-container">
										<slot name="item-selector"></slot>
									</div>
									<div id="budget-range-container">
										${this.getBudgetTemplate_(false)}
									</div>
									<div id="categories-title">
										<h2>your shopping list</h2>
										<h1>this week</h1>
									</div>
									${this.getCategoryTemplate_(
										this._shoppingListPresentation.produce,
										SHOPPING_CATEGORY.PRODUCE,
										this._shoppingListPresentation.produceFulfilled
									)}
									${this.getCategoryTemplate_(
										this._shoppingListPresentation.seafood,
										SHOPPING_CATEGORY.SEAFOOD,
										this._shoppingListPresentation.seafoodFulfilled
									)}
								</div>
								<div class="dd-flex-two-column-child">
									${this.getCategoryTemplate_(
										this._shoppingListPresentation.meatPoultry,
										SHOPPING_CATEGORY.MEAT_POULTRY,
										this._shoppingListPresentation.meatPoultryFulfilled
									)}
									${this.getCategoryTemplate_(
										this._shoppingListPresentation.grocery,
										SHOPPING_CATEGORY.GROCERY,
										this._shoppingListPresentation.groceryFulfilled
									)}
									${this.getCategoryTemplate_(
										this._shoppingListPresentation.dairy,
										SHOPPING_CATEGORY.DAIRY,
										this._shoppingListPresentation.dairyFulfilled
									)}
									${this.getCategoryTemplate_(
										this._shoppingListPresentation.frozenFoods,
										SHOPPING_CATEGORY.FROZEN_FOODS,
										this._shoppingListPresentation.frozenFoodsFulfilled
									)}
									${this.getCategoryTemplate_(
										this._shoppingListPresentation.other,
										SHOPPING_CATEGORY.UNKNOWN,
										this._shoppingListPresentation.otherFulfilled
									)}
									${this.getCategoryTemplate_(
										this._shoppingListPresentation.staples,
										SHOPPING_CATEGORY.STAPLE,
										this._shoppingListPresentation.staplesFulfilled
									)}
								</div>
							</div>
						`
						: html`
							${getSaleTitleTemplate(this.title)}
							<div class="dd-flex-end-container dd-expanded-content-block">
								${this.getOrderTemplate_()}
								<mwc-button
									outlined
									class="dark-filled"
									icon="print"
									label="print"
									title="print this"
									@click="${this.onPrintClick_}"></mwc-button>
							</div>
							<div id="mobile-selector-container">
								<slot name="item-selector"></slot>
							</div>
							<div id="budget-range-container">
								${this.getBudgetTemplate_(false)}
							</div>
							<div id="categories-title">
								<h2>your shopping list</h2>
								<h1>this week</h1>
							</div>
							<div class="dd-child-card-container">
								${this.getCategoryTemplates_()}
							</div>
						`
					}
				</div>
			</div>
			${this.getSpacerTemplate_()}
		`;
	}

	firstUpdated() {
		// external (non-substate) triggers to render
		PubSub.subscribe(OAUTH_CONNECTED_EVENT, (this.updated).bind(this));
		PubSub.subscribe(PRODUCTS_SET_EVENT, (this.updated).bind(this));
	}

	/**
	 * allows child component DOMs to complete initialization before updating
	 */
	asyncUpdateActive() {
		const updateActive = (this.updateActive_).bind(this);

		setTimeout(() => {
			updateActive();
		}, ASYNC_PAUSE);
	}

	maximizeCategories() {
		const categoryCards = this.shadowRoot.querySelectorAll('dd-shop-category-card-2');
		categoryCards.forEach(categoryCard => categoryCard.maximize());
	}

	getItemIdsToFulfill() {
		const dairychecked = this._shoppingListPresentation.dairy.filter(isChecked);
		const frozenChecked = this._shoppingListPresentation.frozenFoods.filter(isChecked);
		const groceryChecked = this._shoppingListPresentation.grocery.filter(isChecked);
		const meatPoultryChecked = this._shoppingListPresentation.meatPoultry.filter(isChecked);
		const produceChecked = this._shoppingListPresentation.produce.filter(isChecked);
		const seafoodChecked = this._shoppingListPresentation.seafood.filter(isChecked);
		const otherChecked = this._shoppingListPresentation.other.filter(isChecked);
		const staplesChecked = this._shoppingListPresentation.staples.filter(isChecked);

		const checkedItems = dairychecked.concat(frozenChecked, groceryChecked, meatPoultryChecked, produceChecked, seafoodChecked, otherChecked, staplesChecked);

		return checkedItems.map(item => item.id);
	}

	hascheckedItems() {
		const dairychecked = this._shoppingListPresentation.dairy.some(isChecked);
		const frozenChecked = this._shoppingListPresentation.frozenFoods.some(isChecked);
		const groceryChecked = this._shoppingListPresentation.grocery.some(isChecked);
		const meatPoultryChecked = this._shoppingListPresentation.meatPoultry.some(isChecked);
		const produceChecked = this._shoppingListPresentation.produce.some(isChecked);
		const seafoodChecked = this._shoppingListPresentation.seafood.some(isChecked);
		const otherChecked = this._shoppingListPresentation.other.some(isChecked);
		const staplesChecked = this._shoppingListPresentation.staples.some(isChecked);
		if(AppFlagProvider.isDebug()) {
			console.log(`dairychecked: ${dairychecked}`);
			console.log(`frozenChecked: ${frozenChecked}`);
			console.log(`groceryChecked: ${groceryChecked}`);
			console.log(`meatPoultryChecked: ${meatPoultryChecked}`);
			console.log(`produceChecked: ${produceChecked}`);
			console.log(`seafoodChecked: ${seafoodChecked}`);
			console.log(`otherChecked: ${otherChecked}`);
			console.log(`staplesChecked: ${staplesChecked}`);
		}

		return dairychecked || frozenChecked || groceryChecked || meatPoultryChecked || produceChecked || seafoodChecked || otherChecked || staplesChecked;
	}

	getCategoryTemplate_(shoppingItems, categoryKey, isFulfilled) {
		return html`
				<dd-shop-category-card-2
					?isOpen=${true}
					?isFulfilled=${isFulfilled}
					.shoppingItems=${shoppingItems}
					.categoryKey=${categoryKey}
					@add-shop-item-to-category="${this.onAddShopItemToCategory_}"
					@maximize-card="${this.onMaximizeCarouselCard_}">
				</dd-shop-category-card-2>
		`;
	}

	getCategoryTemplates_() {
		const categoryTemplates = [];
		const stateInitialized = (this._shoppingListPresentation.dairy.length ||
			this._shoppingListPresentation.frozenFoods.length ||
			this._shoppingListPresentation.grocery.length ||
			this._shoppingListPresentation.meatPoultry.length ||
			this._shoppingListPresentation.produce.length ||
			this._shoppingListPresentation.seafood.length);

		if (stateInitialized) {
			categoryTemplates.push(this.getCategoryTemplate_(
				this._shoppingListPresentation.produce,
				SHOPPING_CATEGORY.PRODUCE,
				this._shoppingListPresentation.produceFulfilled
			));
			categoryTemplates.push(this.getCategoryTemplate_(
				this._shoppingListPresentation.seafood,
				SHOPPING_CATEGORY.SEAFOOD,
				this._shoppingListPresentation.seafoodFulfilled
			));
			categoryTemplates.push(this.getCategoryTemplate_(
				this._shoppingListPresentation.grocery,
				SHOPPING_CATEGORY.GROCERY,
				this._shoppingListPresentation.groceryFulfilled
			));
			categoryTemplates.push(this.getCategoryTemplate_(
				this._shoppingListPresentation.meatPoultry,
				SHOPPING_CATEGORY.MEAT_POULTRY,
				this._shoppingListPresentation.meatPoultryFulfilled
			));
			categoryTemplates.push(this.getCategoryTemplate_(
				this._shoppingListPresentation.dairy,
				SHOPPING_CATEGORY.DAIRY,
				this._shoppingListPresentation.dairyFulfilled
			));
			categoryTemplates.push(this.getCategoryTemplate_(
				this._shoppingListPresentation.frozenFoods,
				SHOPPING_CATEGORY.FROZEN_FOODS,
				this._shoppingListPresentation.frozenFoodsFulfilled
			));
			categoryTemplates.push(this.getCategoryTemplate_(
				this._shoppingListPresentation.other,
				SHOPPING_CATEGORY.UNKNOWN,
				this._shoppingListPresentation.otherFulfilled
			));
			categoryTemplates.push(this.getCategoryTemplate_(
				this._shoppingListPresentation.staples,
				SHOPPING_CATEGORY.STAPLE,
				this._shoppingListPresentation.staplesFulfilled
			));
		}

		return categoryTemplates;
	}

	getOrderTemplate_() {
		let orderCallback = null;

		if (FeatureProvider.isActive(FEATURES.KROGER_FULFILLMENT) && FulfillmentAccountProvider.isKrogerEligible()) {
			orderCallback = this.onKrogerConnectClick_;
		} else if (FeatureProvider.isActive(FEATURES.PEAPOD_FULFILLMENT) && FeatureProvider.isActive(FEATURES.PEAPOD_FULFILLMENT_CONNECT) && FulfillmentAccountProvider.isPeapodEligible()) {
			orderCallback = this.onPeapodConnectClick_;
		} else if (((FeatureProvider.isActive(FEATURES.PEAPOD_FULFILLMENT) && FulfillmentAccountProvider.isPeapodAuthorized()) || (FulfillmentAccountProvider.isKrogerAuthorized() && KrogerProductsProvider.hasProducts()))
				&& this.hascheckedItems()) {
				orderCallback = this.onShopClick_;
		}

		return orderCallback
		?	html`
				<mwc-button
					outlined
					icon="add_shopping_cart"
					label="order"
					class="light-filled dd-button-margin-right"
					title="order online"
					@click="${orderCallback}"></mwc-button>
			`
		: '';
	}

	getPrintMainNamesTemplate_() {
		if (this.activeDayMenus) {
			return this.activeDayMenus.map( activeDayMenu => html`<div>${activeDayMenu.printName}</div>`);
		}

		return '';
	}

	getBudgetTemplate_(isOpen) {
		return (FeatureProvider.isActive(FEATURES.USER_SAVINGS) && this._shoppingListPresentation.costEnabled)? html`
				<dd-budget-range-chart ?isOpen=${isOpen}
					@watch-checked-items-changed="${this.onWatchCheckedItemsChanged_}"
				></dd-budget-range-chart>
			`
			: '';
	}

	updateActive_() {
		const categoryCards = this.shadowRoot.querySelectorAll('dd-shop-category-card-2');
		categoryCards.forEach(categoryCard => categoryCard.updateActive());

		const rangeChart = this.shadowRoot.querySelector('dd-budget-range-chart');
		if (rangeChart) {
			const totalCost = this.getTotalCostOfActiveItems_(rangeChart.watchCheckedItems);
			rangeChart.totalCost = totalCost;
		}
	}

	getTotalCostOfActiveItems_(watchCheckedItems) {
		// get list of items, excluding staples
		let shopItems = [
			...this._shoppingListPresentation.dairy,
			...this._shoppingListPresentation.frozenFoods,
			...this._shoppingListPresentation.grocery,
			...this._shoppingListPresentation.meatPoultry,
			...this._shoppingListPresentation.produce,
			...this._shoppingListPresentation.seafood,
			...this._shoppingListPresentation.other
		];

		if (watchCheckedItems) {
			shopItems = [
				...shopItems,
				...this._shoppingListPresentation.staples
			];
		}

		let activeShopItems = shopItems.filter(shopItem => RecipeShopItemMapProvider.isItemActive(shopItem.id));
		let costMetrics = getCostMetrics(activeShopItems);
		if (costMetrics.costItems > 0) {
			const averageItemCost = costMetrics.total / costMetrics.costItems;

			if (watchCheckedItems) {
				activeShopItems = shopItems.filter(shopItem => shopItem.isChecked && !shopItem.isFulfilled);
				costMetrics = getCostMetrics(activeShopItems);
			}

			// assign avg cost to no-cost items to get total cost
			const totalCost = costMetrics.costItems > 0? costMetrics.total + (averageItemCost * costMetrics.noCostItems) : 0;
			return totalCost;
		}

		return 0;
	}

	updateShoppingListPresentation_() {
		this._shoppingListPresentation = cloneDeep(this._shoppingListOriginal);
		if (!this._sortSimilar) {
			sortByName(this._shoppingListPresentation.dairy);
			sortByName(this._shoppingListPresentation.frozenFoods);
			sortByName(this._shoppingListPresentation.grocery);
			sortByName(this._shoppingListPresentation.meatPoultry);
			sortByName(this._shoppingListPresentation.produce);
			sortByName(this._shoppingListPresentation.seafood);
			sortByName(this._shoppingListPresentation.other);
			sortByName(this._shoppingListPresentation.staples);
		}
	}

	onPrintClick_() {
		const event = new CustomEvent(EVENTS.PRINT_SL, {
			detail: {}
		});
		this.dispatchEvent(event);
	}

	onKrogerConnectClick_() {
		this.onConnectClick_(OAUTH_SERVICE.KROGER);
	}

	onPeapodConnectClick_() {
		this.onConnectClick_(OAUTH_SERVICE.PEAPOD);
	}

	onConnectClick_(service) {
		const event = new CustomEvent(OAUTH_CONNECT_EVENT, {
			detail: {
				service
			}
		});
		this.dispatchEvent(event);
	}

	onShopClick_() {
		const event = new CustomEvent(ELECT_FULFILLMENT_EVENT, {
			detail: {
				shoppingListId: this._shoppingListPresentation.id
			}
		});
		this.dispatchEvent(event);
	}

	onAddShopItemToCategory_(event) {
		const wrappedEvent = new CustomEvent(ADD_SHOP_ITEM_EVENT, {
			detail: {
				...event.detail,
				shoppingListId: this._shoppingListPresentation.id
			}
		});
		this.dispatchEvent(wrappedEvent);
	}

	onWatchCheckedItemsChanged_() {
		this.updateActive_();
	}

	// BEGIN category card carousel methods
	/* eslint class-methods-use-this: "off" */
	onMaximizeCarouselCard_() {
		// stub
	}
	// END category card carousel methods
}

customElements.define('dd-shopping-list-2', DdShoppingList2);
