import { html } from 'lit-element';
import "@material/mwc-button";
import "@material/mwc-icon-button";
import "@material/mwc-icon-button-toggle";
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-select';
import { DdAbstractMenuItemCard } from './dd-abstract-menu-item-card.js';
import { CARD_BODY_ID } from "./dd-card-2.js";
import './dd-card-expander.js';
import '../../dd-max-height-button.js';
import './dd-recipe-card-2.js';
import '../../dd-search.js';
import '../../dd-dialog.js';
import { getProteinNameTemplate, getIconTemplate } from '../../template-helpers.js';
import { EVENTS } from '../../events.js';
import { FavoritesProvider, FAVORITES_SET_EVENT } from '../../../services/favorites-provider.js';
import { PersonalRecipesProvider, PERSONAL_RECIPES_SET_EVENT } from '../../../services/personal-recipes-provider.js';
import { PubSub } from '../../../services/pub-sub.js';
import { SideRecipeOptionsProvider } from '../../../services/side-recipe-options-provider.js';
import { sharedStyles } from '../../../theme/shared-styles.js';
import style from './dd-day-card-2.scss';
import { SALES_ICON, SALES_FG } from '../../../theme/color-classes.js';
import { DISH_TYPE } from '../../../utilities/constants.js';
import { toTimeMeasure } from '../../../utilities/text-format.js';

const OPTION_SUGGESTIONS = 'option-suggestions';
const OPTION_FAVORITES = 'option-favorites';
const OPTION_SEARCH = 'option-search';
const OPTION_PERSONALS = 'option-personals';
const SUGGESTIONS_TITLE = 'Suggestions';
const FAVORITES_TITLE = 'Favorites';
const SEARCH_TITLE = 'Search';
const PERSONALS_TITLE = 'My Recipes';
const REPLACE_OPTIONS = [
	{
		id: OPTION_SUGGESTIONS,
		name: SUGGESTIONS_TITLE
	},
	{
		id: OPTION_FAVORITES,
		name: FAVORITES_TITLE
	},
	{
		id: OPTION_PERSONALS,
		name: PERSONALS_TITLE
	},
	{
		id: OPTION_SEARCH,
		name: SEARCH_TITLE
	}
];
Object.freeze(REPLACE_OPTIONS);

const NUTRITION_DISCLAIMER = 'Nutritional values are a general guideline only. Actual results may vary. Please see our Terms & Conditions for our complete policy.';

function getReplaceOptionsTemplates() {
	return REPLACE_OPTIONS.map((replaceOption) => html`
		<mwc-list-item value="${replaceOption.id}">${replaceOption.name}</mwc-list-item>
		`
	);
}

/**
 * menu day card
 */
class DdDayCard2 extends DdAbstractMenuItemCard {
	static get properties() {
		return {
			searchResults: { type: Object }
		};
	}

	get canAddRecipe() {
		return !(this.hasMain && this.dayMenu.sides && this.dayMenu.sides.length === 2);
	}

	/** pass through to dd-search */
	set searchResults(value) {
		const ddSearch = this.shadowRoot.querySelector('dd-search');
		if (ddSearch) {
			ddSearch.searchResults = value;
		}
	}

  set isAddingRecipe(isAddingRecipe) {
		this._isAddingRecipe = isAddingRecipe;
	}

	get isAddingRecipe() {
		return this._isAddingRecipe;
	}

	set dishReplacing(dishReplacing) {
		this._dishReplacing = dishReplacing;
	}

	get dishReplacing() {
		return this._dishReplacing;
	}


	static get styles() {
		return [
			sharedStyles,
			style
		];
	}

	constructor() {
		super();

		this._dishReplacing = DISH_TYPE.MAIN;
		this._isAddingRecipe = false;
	}

	// BEGIN LitElement lifecycle
	firstUpdated() {
    super.firstUpdated();
		PubSub.subscribe(FAVORITES_SET_EVENT, (this.requestUpdate).bind(this));
		PubSub.subscribe(PERSONAL_RECIPES_SET_EVENT, (this.requestUpdate).bind(this));
	}

  render() {
		const isMain = true;

		return html`
      <div id="${CARD_BODY_ID}" class="dd-card-body-2">
				<dd-card-expander
					?isOpen=${this.isOpen}
					?clickableTitle=${true}
					@pre-open="${this.onExpanderOpen_}">
					${this. getTitleTemplate_()}
					<div id="recipes-container" slot="content">
						${this.getRecipeContentTemplate_()}
						<p class="nutrition-overline">nutritional values</p>
						<div class="content-container">
							<p class="dd-body1">${this.getNutritionValues_()}</p>
							<p class="fine-print">${NUTRITION_DISCLAIMER}</p>
						</div>

						<div class="dd-flex-end-container dd-add-button">
							${this.getAddButtonTemplate_()}
						</div>
					</div>
				</dd-card-expander>
      </div>
			<dd-dialog id="replace-dialog" hideActions @closed="${this.onDialogClosed_}">
				<div id="replace-dialog-container">
					<div id="replace-dialog-header" class="dd-flex-top-container">
						<div id="replace-dialog-title" class="dialog-heading">Swap A Recipe</div>
						<mwc-icon-button icon="close" @click="${this.onCloseDialogClick_}"></mwc-icon-button>
					</div>
					<div id="select-box" class="dd-flex-start-container">
						<span class="select-helper-text dd-pad-right">from</span>
						<div class="dd-day-select-container">
							<mwc-select id="replace-option-select"
								outlined
								fixedMenuPosition
								@action="${this.onReplaceOptionAction_}">
								${getReplaceOptionsTemplates()}
							</mwc-select>
						</div>
					</div>
					<div id="suggested-mains-container" class="dd_hide">
						${this.getSuggestionsTemplate_(isMain)}
					</div>
					<div id="suggested-sides-container" class="dd_hide">
						${this.getSuggestionsTemplate_(!isMain)}
					</div>
					<div id="favorite-mains-container" class="dd_hide">
						${this.getFavoritesTemplate_(isMain)}
					</div>
					<div id="favorite-sides-container" class="dd_hide">
						${this.getFavoritesTemplate_(!isMain)}
					</div>
					<div id="personal-mains-container" class="dd_hide">
						${this.getPersonalsTemplate_(isMain)}
					</div>
					<div id="personal-sides-container" class="dd_hide">
						<span>My Side Recipes will soon be availabe</span>
					</div>
					<div id="search-container" class="dd_hide">
						<dd-search @select-search-option="${this.onSelectSearchOption_}"></dd-search>
					</div>
				</div>
			</dd-dialog>
    `;
  }

	openWithEvent() {
		this.open();
		this.onMaximize_(this.dayMenu.main.id);
	}

	getProteinNameTemplate_() {
		if (this.dayMenu && this.hasMain) {
			const proteinCategory = this.dayMenu.main.proteinCategory;
			return getProteinNameTemplate(proteinCategory);
		}

		return '';
	}

	getAddButtonTemplate_() {
		if (this._dayMenu.main) {
			if (this.canAddRecipe) {
				return html`
					<mwc-button
						outlined
						class="light-filled"
						icon="add_circle"
						@click="${this.onAddRecipeClick_}">add side</mwc-button>
					`
			}

			return '';
		}

		return '';
	}

	getRecipeTemplate_(recipe, location, timeToTable = '') {
		if (recipe) {
			return html`
				<dd-recipe-card-2 id="${location}"
					.recipe=${recipe}
					.location=${location}
					.timeToTable=${timeToTable}
					@replace="${this.onChangeRecipe_}"
					@remove="${this.onDeleteRecipe_}"></dd-recipe-card-2>
			`;
		}

		return '';
	}

	getMobileRecipeTemplates_(main, firstSide, secondSide) {
		const timeToTable = toTimeMeasure(this.dayMenu.timeToTable);
		return html`
			<div id="recipe-container">
				${this.getRecipeTemplate_(main, DISH_TYPE.MAIN, timeToTable)}
				${this.getRecipeTemplate_(firstSide, DISH_TYPE.FIRST_SIDE)}
				${this.getRecipeTemplate_(secondSide, DISH_TYPE.SECOND_SIDE)}
			</div>
		`;
	}

	getRecipeContentTemplate_() {
		if (this.dayMenu) {
			const main = this.dayMenu.main;
			const firstSide = (this.dayMenu && this.dayMenu.sides) ? this.dayMenu.sides[0] : null;
			const secondSide = this.dayMenu.sides ? this.dayMenu.sides[1] : null;

			return this.getMobileRecipeTemplates_(main, firstSide, secondSide);
		}

		return '';
	}

	getNutritionValues_() {
		return `${this.dayMenu.calories} Cals, ${this.dayMenu.protein}g Protein, ${this.dayMenu.fat}g Fat, ${this.dayMenu.fiber}g Fiber, ${this.dayMenu.carbs}g Carbs, ${this.dayMenu.saturatedFat}g Saturated Fat, ${this.dayMenu.sodium}mg Sodium`;
	}

	// BEGIN swap dialog
	/**
 *
 * @param {*} dayMenuAlternative ref Symfony \AppBundle\Controller\Rest\DTO\DayMenuAlternative
 */
	getOptionTemplate_(dayMenuAlternative) {
		const iconClass = dayMenuAlternative.isOnSale? SALES_ICON : '';

		return html`
			<div class="option-container">
				${getIconTemplate('', false, '', '', SALES_FG, iconClass)}
				<div class="option-button-container">
					<dd-max-height-button dense label="${dayMenuAlternative.name}"
						.idValue=${dayMenuAlternative.id}
						@click="${this.onRecipeOptionClick_}"></dd-max-height-button>
				</div>
			</div>
		`;
	}

	getSuggestionsTemplate_(isMain) {
		const suggestions = this.getSuggestions_(isMain);
		return suggestions.map(suggestion => this.getOptionTemplate_(suggestion), this);
	}

	getFavoritesTemplate_(isMain) {
		const favorites = isMain ? FavoritesProvider.getMains() : FavoritesProvider.getSides();

		if (favorites && favorites.length) {
			return favorites.map(favorite => this.getOptionTemplate_(favorite), this);
		}

		return html`<span class="dd-body1">You don't have any Favorites yet. Just tap the heart on an opened recipe card to save it as a Favorite.</span>`;
	}

	getPersonalsTemplate_(isMain) {
		const personals = isMain ? PersonalRecipesProvider.getMains() : PersonalRecipesProvider.getSides();

		if (personals && personals.length) {
			return personals.map(personal => this.getOptionTemplate_(personal), this);
		}

		return html`<span class="dd-body1">Nothing here yet. Just tap My Recipes to create one.</span>`;
	}

	getSuggestions_(isMain) {
		if (isMain) {
			return this.dayMenu.mainRecipeOptions;
		}

		return SideRecipeOptionsProvider.getOptions();
	}

	setInitialReplaceOption_() {
		const replaceOptionSelect = this.shadowRoot.querySelector('#replace-option-select');

		if (replaceOptionSelect) {
			replaceOptionSelect.value = replaceOptionSelect.value || OPTION_SUGGESTIONS;

			this.showReplaceOption_(replaceOptionSelect.value);
		}
	}

	showReplaceOption_(selectValue) {
		switch (selectValue) {
			case OPTION_SUGGESTIONS:
				this.showSuggestionList_();
				break;
			case OPTION_FAVORITES:
				this.showFavoriteList_();
				break;
			case OPTION_PERSONALS:
				this.showPersonalList_();
				break;
			case OPTION_SEARCH:
				this.showSearchList_();
				break;
			case '':
			default:
				break;
		}
	}

	openReplaceDialog_(location, isAdd = false) {
		this.dishReplacing = location;

		const titleVerb = isAdd? 'Add' : 'Swap';
		const titleNoun = (location === DISH_TYPE.MAIN)? 'Main' : 'Side';
		const dialogTitle = this.shadowRoot.querySelector('#replace-dialog-title');
		dialogTitle.innerHTML = `${titleVerb} A ${titleNoun} Recipe`;

		this.setInitialReplaceOption_();
		const dialog = this.shadowRoot.querySelector('#replace-dialog');
		dialog.show();
	}

	closeReplaceDialog_() {
		const radioSelector = this.shadowRoot.querySelector('#replace-option-select');
		if (radioSelector.value === OPTION_SEARCH) {
			this.fireClearSearchEvent_();
		}

		this.hideAllReplacementChoices_();
		const replaceDialog = this.shadowRoot.querySelector('#replace-dialog');
		replaceDialog.close();
	}

	showSuggestedMains_() {
		const containerClasses = this.shadowRoot.querySelector('#suggested-mains-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
	}

	hideSuggestedMains_() {
		const containerClasses = this.shadowRoot.querySelector('#suggested-mains-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	showSuggestedSides_() {
		const containerClasses = this.shadowRoot.querySelector('#suggested-sides-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
	}

	hideSuggestedSides_() {
		const containerClasses = this.shadowRoot.querySelector('#suggested-sides-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	showFavoriteMains_() {
		const containerClasses = this.shadowRoot.querySelector('#favorite-mains-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
	}

	hideFavoriteMains_() {
		const containerClasses = this.shadowRoot.querySelector('#favorite-mains-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	showFavoriteSides_() {
		const containerClasses = this.shadowRoot.querySelector('#favorite-sides-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
	}

	hideFavoriteSides_() {
		const containerClasses = this.shadowRoot.querySelector('#favorite-sides-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	showPersonalMains_() {
		const containerClasses = this.shadowRoot.querySelector('#personal-mains-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
	}

	hidePersonalMains_() {
		const containerClasses = this.shadowRoot.querySelector('#personal-mains-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	showPersonalSides_() {
		const containerClasses = this.shadowRoot.querySelector('#personal-sides-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
	}

	hidePersonalSides_() {
		const containerClasses = this.shadowRoot.querySelector('#personal-sides-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	showSearch_(isMain) {
		const containerClasses = this.shadowRoot.querySelector('#search-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
		const ddSearch = this.shadowRoot.querySelector('dd-search');
		ddSearch.isMain = isMain;
		ddSearch.focus();
	}

	hideSearch_() {
		const containerClasses = this.shadowRoot.querySelector('#search-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	hideAllReplacementChoices_() {
		this.hideSuggestedMains_();
		this.hideSuggestedSides_();
		this.hideFavoriteMains_();
		this.hideFavoriteSides_();
		this.hideSearch_();
	}

	onDialogClosed_(event) {
		const tappedOutsideDialog = (event.detail && (event.detail.action === "close"));
		if (tappedOutsideDialog) {
			this.onCloseDialogClick_();
			this.fireLocalEvent_(EVENTS.CLOSE_DIALOG, {});
		}
	}

	onCloseDialogClick_() {
		this.closeReplaceDialog_();
		if(!this.hasMain) {
			this.close();
			this.isActive = false;
		}
	}

	onReplaceOptionAction_(event) {
		this.showReplaceOption_(event.target.value);
	}

	showSuggestionList_() {
		if (this.dishReplacing === DISH_TYPE.MAIN) {
			this.showSuggestedMains_();
			this.hideSuggestedSides_();
		} else {
			this.hideSuggestedMains_();
			this.showSuggestedSides_();
		}

		this.hideFavoriteMains_();
		this.hideFavoriteSides_();
		this.hidePersonalMains_();
		this.hidePersonalSides_();
		this.hideSearch_();
	}

	showFavoriteList_() {
		if (this.dishReplacing === DISH_TYPE.MAIN) {
			this.showFavoriteMains_();
			this.hideFavoriteSides_();
		} else {
			this.hideFavoriteMains_();
			this.showFavoriteSides_();
		}

		this.hidePersonalMains_();
		this.hidePersonalSides_();
		this.hideSuggestedMains_();
		this.hideSuggestedSides_();
		this.hideSearch_();
	}

	showSearchList_() {
		this.showSearch_(this.dishReplacing === DISH_TYPE.MAIN);

		this.hideSuggestedMains_();
		this.hideSuggestedSides_();
		this.hideFavoriteMains_();
		this.hideFavoriteSides_();
		this.hidePersonalMains_();
		this.hidePersonalSides_();
	}

	showPersonalList_() {
		if (this.dishReplacing === DISH_TYPE.MAIN) {
			this.showPersonalMains_();
			this.hidePersonalSides_();
		} else {
			this.hidePersonalMains_();
			this.showPersonalSides_();
		}

		this.hideFavoriteMains_();
		this.hideFavoriteSides_();
		this.hideSuggestedMains_();
		this.hideSuggestedSides_();
		this.hideSearch_();
	}
	// END swap dialog

	onAddRecipeClick_() {
		// start with main dish if null, then first side, then second side
		this.isAddingRecipe = true;
		let location = DISH_TYPE.MAIN;
		if (this.hasMain) {
			location = this.dayMenu.sides[0] ? DISH_TYPE.SECOND_SIDE : DISH_TYPE.FIRST_SIDE;
		}
		this.openReplaceDialog_(location, true);
	}

	// BEGIN event generators
	fireRecipeChangeEvent_(recipeId) {
		// event to bubble out of parent's shadow DOM
		const eventName = this.isAddingRecipe ? EVENTS.ADD_RECIPE : EVENTS.REPLACE_RECIPE;
		const detail = {
			location: this.dishReplacing,
			dayMenuId: this.dayMenu.id,
			recipeId
		};
		this.fireGlobalEvent_(eventName, detail);
		this.closeReplaceDialog_();
	}

	fireClearSearchEvent_() {
		this.fireGlobalEvent_(EVENTS.CLEAR_SEARCH, {});
	}

	onChangeRecipe_(event) {
		this.isAddingRecipe = false;
		this.openReplaceDialog_(event.detail);
	}

	onDeleteRecipe_(event) {
		const location = event.detail;
		if (location === DISH_TYPE.MAIN) {
			this.onDeleteRecipes_();
		} else {
			const detail = {
				location,
				dayMenuId: this.dayMenu.id
			};
			this.fireGlobalEvent_(EVENTS.DELETE_RECIPE, detail);
		}
	}

	onDeleteRecipes_() {
		this.fireGlobalEvent_(EVENTS.DELETE_RECIPES, this.dayMenu.id);
		this.close();
		this.isActive = false;
	}

	onRecipeOptionClick_(event) {
		this.fireRecipeChangeEvent_(event.target.idValue);
	}

	onSelectSearchOption_(event) {
		this.fireRecipeChangeEvent_(event.detail.recipeId);
	}

	onExpanderOpen_() {
		if (this.dayMenu.main) {
			this.isOpen = true;
			this.onMaximize_(this.dayMenu.main.id);
		}
	}
}

customElements.define('dd-day-card-2', DdDayCard2);