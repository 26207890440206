import { html, css } from 'lit-element';
import "@material/mwc-button";
import "@material/mwc-icon-button"

import './dd-week-select.js';
import './dd-card/dd-day-card-2.js';
import { TRANSITION_PAUSE } from './dd-card/dd-card-expander.js';
import { WakePageViewElement } from './wake-page-view-element.js';
import { FEATURES_SET_EVENT } from '../../services/feature-provider.js';
import { Instrumentation } from '../../services/instrumentation-service.js';
import { PubSub } from '../../services/pub-sub.js';
import { sharedStyles } from '../../theme/shared-styles.js';
import { AUTO_COLLAPSE } from '../../utilities/constants.js';
import { offsetScrollToTop } from '../../utilities/dom.js';
import { EVENTS } from '../events.js';

export const ALL_DAYS_ID = -1;
const CURRENT_WEEK_LABEL = "go to this week";
const PREVIOUS_WEEK_LABEL = "go to last week";

/**
 * menu screen
 */
class DdMenu2 extends WakePageViewElement {
	static get properties() {
		return {
			previousWeek: { type: Boolean },
			weekMenu: { type: Object },
			searchResults: { type: Object }
		};
	}

	static get styles() {
		return [
			sharedStyles,
			css`
				#grid-inner {
					/* */
				}

				#restore-container {
					margin: 8px 0;
				}

				.grid-outer {
					padding: 14px 24px 8px 24px;
				}

				.dd-flex-container > mwc-button:first-child {
					margin-right: 32px;
				}

				.child-cards-container {
					margin-top: -8px;
				}

				.dd-child-card-container > *:not([isactive]) {
						display: none;
				}

				.menu-button-row {
					margin: 16px 8px 16px 8px;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}

				@media only screen and (min-width: 1248px) {
					.grid-outer {
						padding: 14px 24px 8px 32px;
					}

					.child-cards-container {
						margin-bottom: 16px;
					}
				}
			`
		];
	}

	get hasDayMenus() {
		return this.weekMenu &&  Array.isArray(this.weekMenu.dayMenus) && this.weekMenu.dayMenus.length;
	}

	/** pass through to children property */
	set searchResults(value) {
		const dayCards = this.shadowRoot.querySelectorAll('dd-day-card-2');
		dayCards.forEach(dayCard => {
			dayCard.searchResults = value;
		});
	}

	constructor() {
		super();
		this.previousWeek = false;
		this.weekMenu = {
			name: '',
			metadata: { isNewUser: false }
		};

		// non-state properties
		this._dayCardSelected = false;
	}

	firstUpdated() {
    super.firstUpdated();
		PubSub.subscribe(FEATURES_SET_EVENT, (this.requestUpdate).bind(this));

		if (this.weekMenu && this.weekMenu.metadata) {
			const analytics = Instrumentation.getAnalytics();
			analytics.identify(this.weekMenu.metadata.id);
			analytics.people.set_once({
				"company": this.weekMenu.metadata.company,
				"coupon": this.weekMenu.metadata.couponCode
			});
		}
	}

	updated() {
    super.updated();
		// maximize right pane card
		const maximizedCard = this.shadowRoot.querySelector('#maximized-card');
		if (maximizedCard) {
			maximizedCard.maximize();
		}
  }

	render() {
		const weekSelectVisbilityClass= !this.weekMenu.metadata.isNewUser? 'dd-visible' : 'dd-invisible';

		return html`
			<div class="grid-outer">
				<div id="grid-inner">
					<div class="dd-flex-container">
						<mwc-button
							outlined
							class="dark-filled"
							icon="print"
							label="print"
							title="print this"
							@click="${this.onPrintClick_}"></mwc-button>

							${this.largeScreen
								? html`
									<mwc-button
										icon="event"
										label="add to calendar"
										title="add to calendar"
										@click="${this.onCalendarClick_}"></mwc-button>
								`
								: html`
									<mwc-icon-button icon="event"
										title="add this menu to my calendar"
										@click="${this.onCalendarClick_}"></mwc-icon-button>
								`
							}

						<dd-week-select class="${weekSelectVisbilityClass}"
							?previousWeek=${this.previousWeek}
							@week-action="${this.onSelectWeekAction_}"></dd-week-select>
					</div>
					<div id="child-cards-container" class="dd-child-card-container">
							${this.getDayMenuTemplates_()}
					</div>
					${this.getAddMealTemplate_()}
					<div id="restore-container">
						<mwc-button id="restore-button"
							label="restore original"
							?disabled=${this.weekMenu.isOriginal}
							@click="${this.onRestoreClick_}"></mwc-button>
					</div>
				</div>
			</div>
			${this.getSpacerTemplate_()}
		`;
	}

	disableMenuChangeControls() {
		this.setMenuChangeControls_(false);
	}

	enableMenuChangeControls() {
		this.setMenuChangeControls_(true);
	}

	select(dayMenuId) {
		const dayCards = this.shadowRoot.querySelectorAll('dd-day-card-2');

		if (dayMenuId === ALL_DAYS_ID) {
			dayCards.forEach(dayCard => {
				dayCard.open();
			});
		} else {
			this._dayCardSelected = true;
			const dayId = dayMenuId;

			dayCards.forEach(dayCard => {
				if (dayCard.dayMenu.id === dayId) {
					dayCard.openWithEvent();
				}
			});
		}
	}

	setMenuChangeControls_(enable) {
		const weekSelect = this.shadowRoot.querySelector('dd-week-select');
		if (weekSelect) {
			weekSelect.disabled = !enable || this.weekMenu.metadata.isNewUser;
		}

		const restoreButton = this.shadowRoot.querySelector('#restore-button');
		if (restoreButton) {
			restoreButton.disabled = !enable || this.weekMenu.isOriginal;
		}

		const menuLimitsButton = this.shadowRoot.querySelector('#menu-limits-button');
		if (menuLimitsButton) {
			menuLimitsButton.disabled = !enable;
		}

		const dietaryRestrictionsButton = this.shadowRoot.querySelector('#dietary-restrictions-button');
		if (dietaryRestrictionsButton) {
			dietaryRestrictionsButton.disabled = !enable;
		}
	}

	getDietaryRestrictionsButton() {
		return this.shadowRoot.querySelector('#dietary-restrictions-button');
	}

	getMenuLimitsButton() {
		return this.shadowRoot.querySelector('#menu-limits-button');
	}

	getWeekButtonLabel_() {
		return this.previousWeek? CURRENT_WEEK_LABEL : PREVIOUS_WEEK_LABEL;
	}

	getDayMenuTemplates_() {
		if (this.weekMenu && this.weekMenu.dayMenus) {
			let setMaximized = false;

			return this.weekMenu.dayMenus.map(dayMenu => {
				let isOpen = false;
				if (!setMaximized && dayMenu.main) {
					setMaximized = true;
					isOpen = true;
				}

				return html`
					<dd-day-card-2
						?isOpen=${isOpen}
						.dayMenu=${dayMenu}
						@maximize-card="${this.onMaximizeCarouselCard_}">
					</dd-day-card-2>
				`;
			});
		}

		return '';
	}

	getAddMealTemplate_() {
		const canAddMeal = this.weekMenu && this.weekMenu.dayMenus && (this.weekMenu.dayMenus.filter(dayMenu => !dayMenu.main).length > 0);

		return canAddMeal
		?	html`
				<div class="dd-flex-end-container">
					<mwc-button
						outlined
						class="white-filled-cta"
						icon="add_circle"
						label="add meal"
						title="Add meal"
						@click="${this.onAddMealClick_}"></mwc-button>
				</div>
			`
		: '';
	}

	getEmptyDayCards_() {
		const dayCards = this.shadowRoot.querySelectorAll('dd-day-card-2');
		const emptyDayCards = Array.from(dayCards).filter(dayCard => !dayCard.dayMenu.main);
		return emptyDayCards;
	}

	onAddMealClick_() {
		const emptyDayCards = this.getEmptyDayCards_();
		if (emptyDayCards[0]) {
			emptyDayCards[0].isActive = true;
			emptyDayCards[0].open();
			emptyDayCards[0].onAddRecipeClick_();
		}
	}

	// BEGIN day card carousel methods
	onMaximizeCarouselCard_(event) {
		if (AUTO_COLLAPSE || this._dayCardSelected) {
			this._dayCardSelected = false;

			const dayCards = this.shadowRoot.querySelectorAll('dd-day-card-2');
			dayCards.forEach(card => {
				if (!card.dayMenu.main || (card.dayMenu.main.id !== event.detail.id)) {
					card.close();
				}
			});

			const paddedPause = 2 * TRANSITION_PAUSE;
			offsetScrollToTop(event.target, paddedPause);
		}
	}

	// END day card carousel methods

	onRestoreClick_() {
		const event = new CustomEvent(EVENTS.RESTORE_MENU, {
			detail: {}
		});
		this.dispatchEvent(event);
	}

	onPrintClick_() {
		const event = new CustomEvent(EVENTS.PRINT_MENU, {
			detail: {}
		});
		this.dispatchEvent(event);
	}

	onCalendarClick_() {
		const event = new CustomEvent(EVENTS.ADD_CALENDAR, {
			detail: {}
		});
		this.dispatchEvent(event);
	}

	onSelectWeekAction_() {
		const event = new CustomEvent(EVENTS.CHANGE_WEEK_MENU, {
			detail: {}
		});
		this.dispatchEvent(event);
	}
}

customElements.define('dd-menu-2', DdMenu2);
