import { html, css } from 'lit-element';
import "@material/mwc-icon-button";
import { sharedStyles } from '../theme/shared-styles.js';
import style from './dd-print.scss';
import { PRINT_CONTAINER, VIEW_CONTAINER, DdPrint } from './dd-print.js';
import { getProteinIconTemplate, getProteinNameTemplate } from './template-helpers.js';
import { FAMILY_SIZE } from '../utilities/constants.js';
import { getDaySidesTitle, toTimeMeasure } from '../utilities/text-format.js';

/**
 * week menu print view
 */

function getSecondTitleTemplate(dayMenu) {
	const sidesTitle = getDaySidesTitle(dayMenu);

	if (sidesTitle) {
		return html`<span class="print-title-connector">with </span><span class="print-view-subtitle">${sidesTitle}</span>`;
	}

	return '';
}

function 	getIngredientsTemplate(ingredients) {
	return ingredients.map( ingredient => html`<div>${ingredient}</div>`);
}

function 	getRecipeTemplate(recipe, isSide) {
	if (!recipe) {
		return '';
	};

	const nameTemplate = isSide
	? html`<div class="bold_title">${recipe.name}</div>`
	: '';

	return html`
				<div class="print-recipe-container">
					${nameTemplate}
					<div class="dd-flex-top-container">
						<div class="print-day-column">
							${getIngredientsTemplate(recipe.ingredients)}
						</div>
						<div class="print-day-column">
							${recipe.instructions}
						</div>
					</div>
				</div>
	`;
}

function getDayMenuTemplate(dayMenu) {
	const timeToTable = toTimeMeasure(dayMenu.timeToTable);

	return dayMenu.main
	? html`
			<div class="print-day-menu-container dd-line-divider">
				<div class="print-day-menu-header">
					<div>
						<span class="print-view-day-title">${dayMenu.mainName}</span>
						${getSecondTitleTemplate(dayMenu)}
					</div>
					<div class="dd-flex-start-container italic">
						${getProteinIconTemplate(dayMenu.main.proteinCategory, dayMenu.main.isPersonal)}
						${getProteinNameTemplate(dayMenu.main.proteinCategory)}
						<span class="dd-bar-spacing">|</span>
						<span class="material-icons print-pad-right">schedule</span>
						<div class="bold_title print-note-title print-pad-right">time to table:</div>
						<span>${timeToTable}</span>
						${dayMenu.cornerNote
							? html`<span class="dd-bar-spacing">|</span>`
							: ''
						}
						<div id="corner-note">${dayMenu.cornerNote}</div>
					</div>
					<div class="">
						<div class="bold_title dd-day-nutrition-line">nutrition for meal:</div>
						<div class="dd-day-nutrition-line">${dayMenu.calories} Cals</div>
						<div class="dd-day-nutrition-line">${dayMenu.protein}g Protein</div>
						<div class="dd-day-nutrition-line">${dayMenu.fat}g Fat</div>
						<div class="dd-day-nutrition-line">${dayMenu.fiber}g Fiber</div>
						<div class="dd-day-nutrition-line">${dayMenu.carbs}g Carbs</div>
						<div class="dd-day-nutrition-line">${dayMenu.saturatedFat}g Saturated Fat</div>
						<div class="dd-day-nutrition-line">${dayMenu.sodium}mg Sodium</div>
					</div>
				</div>
				<div class="dd-flex-top-container">
					<div class="print-day-column print-day-column-heading">ingredients</div>
					<div class="print-day-column print-day-column-heading">instructions</div>
				</div>
				${getRecipeTemplate(dayMenu.main, false)}
				${dayMenu.sides.map( side => getRecipeTemplate(side, true))}
			</div>
		`
	: '';
}

class DdWeekMenuPrint extends DdPrint {
	static get properties() {
		return {
			heading: { type: Object },
			weekMenu: { type: Object }
		};
	}

	static get styles() {
		return [
			sharedStyles,
			style,
			css`
				.print-day-column {
					flex: 1 1 0px;
					height: 100%;
				}

				span.print-view-day-title {
					text-transform: capitalize;
					font-size: 20px;
					font-weight: bold;
				}

				.print-title-connector {
					font-size: 16px;
					font-style: italic;
    			text-transform: lowercase;
					margin: 0 8px;
				}

				span.print-view-subtitle {
					text-transform: capitalize;
					font-size: 18px;
				}

				.print-day-menu-container {
					padding-bottom: 16px;
					break-inside: avoid;
				}

				.print-day-menu-container:last-of-type {
					page-break-after: avoid;
				}

				.print-day-menu-header {
					padding: 16px 0;
					line-height: 1.7;
				}

				.print-recipe-container {
					margin: 4px 0;
				}

				.print-day-column-heading {
					font-style: italic;
    			text-transform: capitalize;
					color: var(--mdc-theme-on-surface)
				}

				.print-note-title {
					white-space: nowrap;
				}

				.print-pad-right {
					padding-right: 4px;
				}

				.dd-day-nutrition-line {
						display: inline-block;
						margin-right: 12px;
				}
			`
    ];
  }

	constructor() {
		super();
		this.previousWeek = false;
		this.weekMenu = {
			name: '',
			metadata: { isNewUser: false }
		};
	}

  render() {
		this.scrollSpinnerContainer_();
		const planDescription = this.weekMenu.metadata.familySize === FAMILY_SIZE.full.value? FAMILY_SIZE.full.description : FAMILY_SIZE.half.description;
		const firstName = this.weekMenu.metadata.first? `${this.weekMenu.metadata.first}'s ` : '';

    return html`
			<div id="${VIEW_CONTAINER}">
				${this.getScrimTemplate_()}
				<div id="${PRINT_CONTAINER}">
					<div>
						<h4 class="bold_title">${this.heading}</h4>
					</div>
					<p>${firstName}Menu<span class="dd-bar-spacing">|</span>Serves ${planDescription}</p>
					${this.getDayMenuTemplates_()}
				</div>
			</div>
    `;
  }

	getDayMenuTemplates_() {
		return this.weekMenu.dayMenus
		? this.weekMenu.dayMenus.map( dayMenu => getDayMenuTemplate(dayMenu))
		: '';
	}
}

customElements.define('dd-week-menu-print', DdWeekMenuPrint);
